import { useState, useEffect, useContext } from "react";
import { Button, TextField, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import Sidebar from "../ui/sidebar";
import TopNav from "../ui/top-nav";
import { Badge, Event, AccessTime } from "@mui/icons-material";
import { AuthContext } from "../auth";


interface ReplacementDict {
    '-name-': string,
    '-day-': string,
    '-time-': string
}



function newPreviewMessage(input: string): string {
    
	const replacements: ReplacementDict = {
        '-name-': '[Recipient\'s Name]', 
		'-day-': 'Tuesday', 
		'-time-': '12:30 PM'
    };
    
    Object.keys(replacements).forEach((token) => {
        input = input.replaceAll(token, replacements[token as keyof ReplacementDict]);
    });

    return input;
}


export default function Reminders() {

	const authInfo = useContext(AuthContext);
    const [formIdx, setFormIdx] = useState(0);
    const [apptReminderText, setApptReminderText] = useState('');
    const [reviewReminderText, setReviewReminderText] = useState('');

    // Confirmation modal state
    const [open, setOpen] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [hasChanges, setHasChanges] = useState(false); // Track changes

	if(!authInfo) {
		throw new Error("Invalid auth context");
	}
	const userData = authInfo.userData;

	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);

	let accountData = userData?.account_data;

    let reminderMessages = [accountData ? accountData.appointment_message : '',
        accountData ? accountData.review_message : ''];

    //console.log('Messages:');
    //console.log(reminderMessages);



    if(reminderMessages[0] && reminderMessages[1] && !apptReminderText && !reviewReminderText) {
        setApptReminderText(reminderMessages[0]);
        setReviewReminderText(reminderMessages[1]);
    }

    async function submitReminderChanges() {

        if(!apptReminderText.trim() || !reviewReminderText.trim()) {
            // Alert user (last resort, they should have been validated by now)
            return;
        }

        const response = await fetch('/update-reminders', 
            {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    'booking_reminder': apptReminderText,
                    'review_reminder': reviewReminderText
                })
            }
        );

        if(response.status !== 200) {
            alert("There was an error updating your reminder templates. Try again later.");
        }

        // Now fetch user data again to display the updated templates.
        window.location.reload();
    }

    const handleClickOpen = () => {
      if (reviewReminderText.trim() === "" || apptReminderText.trim() === "") {
        setValidationError(true);
        return; // Don't open the dialog if validation fails
      }
      setValidationError(false);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleConfirm = async () => {
      setOpen(false);
      setHasChanges(false);
      try {
        await submitReminderChanges(); 

        // Optionally show a success message here.
        //console.log("Reminder changes saved successfully!");
      } catch (error) {
        // Handle errors from the onSubmit function
        console.error("Error saving reminder changes:", error);
        // Optionally show an error message to the user
      }
    };

    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (hasChanges) {
          event.preventDefault(); // Cancel the event
          event.returnValue = 'You have unsaved changes. Are you sure you want to leave?'; // Chrome requires returnValue
          return 'You have unsaved changes. Are you sure you want to leave?'; // For other browsers
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload); // Clean up on unmount
      };
    }, [hasChanges]); // Important: Add hasChanges as a dependency

    const handleReviewTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setReviewReminderText(event.target.value);
      setHasChanges(true); // Set hasChanges to true on change
    };

    const handleApptTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setApptReminderText(event.target.value);
      setHasChanges(true); // Set hasChanges to true on change
    };

    return (

        <div className="bg-slate-50 h-screen">
            <Sidebar />

            <TopNav />

            <main className="md:ml-64 p-8 pt-28">

				<h1 className="text-2xl text-heading pb-8">Custom Reminder Messages</h1>

                <div className="bg-white rounded-md p-4">

                    <Tabs value={formIdx} onChange={(event: React.SyntheticEvent, newVal: number) => setFormIdx(newVal)}>
                        <Tab label="Appointment Reminder" className="text-xl" />
                        <Tab label="Review Reminder" className="text-xl" />
                    </Tabs>

                    <div className={"lg:p-8 flex flex-col md:flex-row justify-evenly" + (formIdx !== 0 ? " hidden" : "")}>
                        {/* Appointment Reminder editor */}
                        <div className="lg:w-1/2 w-full p-8">

                            <div className="text-center lg:flex lg:flex-row justify-evenly lg:justify-between mb-4">
                                <h1 className="text-lg text-heading">Edit</h1>
                                <div>
                                    <Button 
                                        onClick={() => setApptReminderText(apptReminderText + ' -name- ')}
                                    >
                                        <Badge className="mr-1"/> <span className="text-xs">Add Name</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setApptReminderText(apptReminderText + ' -day- ')}
                                    >
                                        <Event className="mr-1 ml-2"/> <span className="text-xs">Add Date</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setApptReminderText(apptReminderText + ' -time- ')}
                                    >
                                        <AccessTime className="mr-1 ml-2"/> <span className="text-xs">Add Time</span>
                                    </Button>
                                </div>
                            </div>

                            <TextField 
                                multiline 
                                onChange={handleApptTextChange} 
                                className="w-full" 
                                value={apptReminderText} 
                                rows={12}
                                error={validationError && apptReminderText.trim() === ""}
                                helperText={validationError && apptReminderText.trim() === "" ? "This field is required" : ""}
                            />
                            <Button variant="outlined" className="float-right top-4" onClick={() => {setApptReminderText('')}}>Clear</Button>
                        </div>

                        {/* Appointment Reminder Preview */}
                        <div className="bg-[#F4F9FF] p-8 rounded-md lg:w-1/2">
                            <h1 className="text-lg text-heading mb-4">Preview Template</h1>

                            <TextField 
                                multiline 
                                value={newPreviewMessage(apptReminderText)} 
                                className="w-full" 
                                rows={12} 
                                variant="filled" 
                                inputProps={{readOnly: true,}} 
                            />
                        </div>
                    </div>


                    <div className={"lg:p-8 flex flex-col md:flex-row justify-evenly" + (formIdx !== 1 ? " hidden" : "")}>

                        {/* Review Reminder Editor */}
                        <div className="lg:w-1/2 w-full p-8">
                            <div className="text-center lg:flex lg:flex-row justify-evenly lg:justify-between mb-4">
                                <h1 className="text-lg text-heading">Edit</h1>
                                <div>
                                    <Button 
                                        onClick={() => setReviewReminderText(reviewReminderText + ' -name- ')}
                                    >
                                        <Badge className="mr-1"/> <span className="text-xs">Add Name</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setReviewReminderText(reviewReminderText + ' -day- ')}
                                    >
                                        <Event className="mr-1 ml-2"/> <span className="text-xs">Add Date</span>
                                    </Button>
                                    <Button 
                                        onClick={() => setReviewReminderText(reviewReminderText + ' -time- ')}
                                    >
                                        <AccessTime className="mr-1 ml-2"/> <span className="text-xs">Add Time</span>
                                    </Button>
                                </div>
                            </div>
                            <TextField 
                                multiline 
                                onChange={handleReviewTextChange} 
                                className="w-full" 
                                value={reviewReminderText} 
                                rows={12}
                                error={validationError && reviewReminderText.trim() === ""}
                                helperText={validationError && reviewReminderText.trim() === "" ? "This field is required" : ""}
                            />
                            <Button variant="outlined" className="float-right top-4" onClick={() => {setReviewReminderText('')}}>Clear</Button>
                        </div>

                        {/* Appointment Reminder Preview */}
                        <div className="bg-[#F4F9FF] p-8 rounded-md lg:w-1/2">
                            <h1 className="text-lg text-heading mb-4">Preview Template</h1>
                            <TextField multiline value={newPreviewMessage(reviewReminderText)} className="w-full" rows={12} variant="filled" inputProps={{readOnly: true,}}></TextField>
                        </div>
                    </div>

                    <div className="w-full text-center mt-12">
                        <Button variant="contained" className="w-fit" onClick={handleClickOpen}>Save</Button>
                    </div>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{"Confirm Save"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to save these reminder changes?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleConfirm} autoFocus>
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>

                </div>

            </main>
        </div>

    );
}
