
import React, { useState } from 'react';
import { TextField, Typography, Box, Alert, AlertColor, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  message: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#C82AF8",
  color: "white",
  padding: "1rem 2rem",
  fontSize: '1.25rem',
  '&:hover': {
    backgroundColor: "#A608D6",
  },
}));

export default function ContactForm() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const [alertMessage, setAlertMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const data: FormData = {
      first_name: formData.get('first_name') as string,
      last_name: formData.get('last_name') as string,
      email: formData.get('email') as string,
      message: formData.get('message') as string,
    };

    try {
      const response = await fetch('/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setAlertSeverity('success');
        setAlertMessage('Message sent successfully!');
        form.reset();
      } else {
        const errorData = await response.json();
        setAlertMessage(errorData?.message || `Error sending message: Status ${response.status}`);
        setAlertSeverity('error');
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setAlertMessage('An unexpected error occurred. Please try again later.');
      setAlertSeverity('error');
    } finally {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
    }
  };

  return (
    <Box className="bg-[#F5FEFE] p-6 lg:p-24" id="contact-form">

      <Box className="max-w-7xl mx-auto lg:flex lg:flex-row items-center justify-between">

        <Box className="lg:w-1/2 mb-8 lg:mb-0 lg:p-4">
          <Typography variant="h2" className="font-extrabold text-4xl lg:text-6xl text-[#464748] mb-4">
            Get in touch with us
          </Typography>
          <Typography variant="body1" className="text-[#464748]" sx={{ fontSize: { xs: '1.125rem', md: '1.25rem', lg: '1.5rem' } }}>
            We'd love to hear from you!  Please fill out the form or contact us directly.
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit} className="w-full lg:w-1/2 p-6 bg-white rounded-lg shadow-md">
          {showAlert && (
            <Alert severity={alertSeverity} onClose={() => setShowAlert(false)} className="mb-4">
              {alertMessage}
            </Alert>
          )}
          <Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <TextField label="First Name" name="first_name" placeholder="Type First Name" margin="normal" fullWidth required />
            <TextField label="Last Name" name="last_name" placeholder="Type Last Name" margin="normal" fullWidth required />
          </Box>
          <TextField label="Email" name="email" placeholder="Type Email" fullWidth margin="normal" type="email" required />
          <TextField label="Message" name="message" placeholder="Type Message here" fullWidth margin="normal" rows={4} required />
            
          {/*
          <StyledButton type="submit" fullWidth className="mt-4 bg-purple-600">Send</StyledButton>
          */}
          <button 
            className="gap-2 px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white bg-purple-600 rounded-lg shadow-lg hover:bg-purple-700 transition-colors w-full text-center"
            type="submit"
          >
            Send
          </button>
        </Box>
      </Box>
    </Box>
  );
};
