import { useContext, useEffect } from "react";
import { AuthContext } from "../auth";
import Sidebar from "../ui/sidebar";
import TopNav from "../ui/top-nav";
import { Message } from "../types"; // Import Message type
import dayjs from 'dayjs';
import { Card, CardContent, Typography } from "@mui/material";

export const MAX_MESSAGES_TO_DISPLAY = 15; // Constant for max messages

interface CustomerMessage extends Message {
    customerName:  string,
    customerPhone: string
};

export default function Inbox() {
    const authInfo = useContext(AuthContext);
    if (!authInfo) throw new Error("Invalid auth context.");

    useEffect(() => {
        if (!authInfo.authed && !authInfo.fetchingAuth) {
            window.location.replace("/login");
        }
    }, [authInfo.fetchingAuth]);

    const userData = authInfo.userData;

    const getIncomingMessages = () => {
        if (!userData || !userData.booking_list) return [];

        console.log("User data (inbox page):");

        const allMessages: CustomerMessage[] = [];

        userData.booking_list.forEach((booking) => {

            if (booking.message_history) {
                booking.message_history.forEach((message) => {
                    if (!message.outgoing) { // Only incoming messages
                        console.log("Incoming message:");
                        console.log(message);
                        allMessages.push({
                            ...message,
                            customerName: `${booking.first_name} ${booking.last_name}`,
                            customerPhone: booking.phone
                        });
                    }
                });
            }
        });

        // Sort by date (newest first)
        allMessages.sort((a, b) => b.timestamp - a.timestamp);

        return allMessages.slice(0, MAX_MESSAGES_TO_DISPLAY); // Limit to MAX_MESSAGES_TO_DISPLAY
    };

    const incomingMessages = getIncomingMessages();

  return (
    <div className="bg-slate-50 h-screen">
      <Sidebar />
      <TopNav />

      <main className="md:ml-64 p-8 pt-28">
        <Typography variant="h5" component="h1" className="text-heading">
          Inbox
        </Typography>

        <Typography variant="body2" color="text.secondary" gutterBottom>
          Showing the most recent {MAX_MESSAGES_TO_DISPLAY} messages from all contacts.
        </Typography>

        <div className="bg-white rounded-md p-4">
          {incomingMessages.length === 0 ? (
            <p>No messages yet.</p>
          ) : (
            <ul className="space-y-4">
              {incomingMessages.map((message, index) => (
                <Card key={index} className="shadow-sm">
                  <CardContent>
                    <Typography variant="body1">{message.customerName}</Typography>
                    <Typography variant="body2">{message.customerPhone}</Typography>
                    <p className="text-gray-800 mt-4 mb-4">{message.content}</p>
                    <p className="text-gray-500 text-sm mt-1">
                      {dayjs(message.timestamp * 1000).format("DD MMM YYYY HH:mm")}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </ul>
          )}
        </div>
      </main>
    </div>
  );
}
