/*
	Handles user authentication and data fetching
*/

import { createContext, useState, useEffect } from "react";
import { AuthContextType, CampaignApiCache, UserData} from "./types";


/* Type definitions */
const PUBLIC_PAGES = ["/", "/login", "/signup", "/new-booking", "/subscribe", "/privacy-policy"];
//const PRO_PAGES = [];

const AuthContext = createContext<AuthContextType | null>(null);


function AuthProvider({ children }: { children: JSX.Element }) {

	const [authed, setAuthed] = useState(false); // Login state
	const [fetchingAuth, setFetchingAuth] = useState(true);
	const [googleAuthed, setGoogleAuthed] = useState(false); // Google OAuth state
	const [userData, setUserData] = useState<UserData | null>(null); 

	/* Pulls profile data and cached campaign data */
	async function fetchUserData() {
		const res = await fetch("/get-user-data");

		if(res.status !== 200) {

			// Unsuccessful data fetch
            //if(res.status === 401) {
            //    window.location.href = "/login";
            //}

			return;
		}
		
		try {
			const data: UserData = await res.json();

			setUserData(data);
			if(data.google_authed) {
				setGoogleAuthed(true);
			}
		} catch {
			// Couldn't parse JSON data
			//alert("Couldn't fetch user data!");
		}
	}

	
	/* Forces the server to request new campaign data and re-generate AI analysis */
	async function forceUpdateCampaignCache() {
		const res = await fetch("/get-campaign-data");
		if(res.status !== 200) {
			// Unsuccessful data fetch
			return;
		}
		const data: CampaignApiCache = await res.json();
		
		// Update api_cache field
		if(userData) {
			setUserData({
				...userData, 
				account_data: { 
					...userData.account_data,
					api_cache: data
				}
			});
		}
	}

	async function validateToken() {
		const res = await fetch("/validate-token");

		if(res.status !== 200) {
            console.log("Couldn't validate token.");

            // If we're not on a public page, redirect
            if (!PUBLIC_PAGES.includes(window.location.pathname)) {
                window.location.href = "/login";
                return;
            }
        }

		setAuthed(true);
		setFetchingAuth(false);
		fetchUserData();
	}
	
	const removeAuth = async () => {
		const response = await fetch('/unauth');

		if(response.status === 200) {
            // 'See other' redirect indicates successful authorization
			setGoogleAuthed(false);
		} else {
			// Couldn't disconnect Google Account
		}
	};


	/* Handles setting authentication status */
	function login() {
		setAuthed(true);
	}
	function authGoogle() {
		//setGoogleAuthed(true);
        window.location.href = "/auth";
	}
	function logout() {
		setAuthed(false);
	}
	function unauthGoogle() {
		removeAuth();
	}


	/* Fetch user data on first mount */
	useEffect(() => {
		validateToken();
	}, []);
	

	return (
		<AuthContext.Provider value={{authed, fetchingAuth, login, logout, 
									googleAuthed, authGoogle, unauthGoogle, 
									userData, fetchUserData, 
									forceUpdateCampaignCache}}>
            {children}
		</AuthContext.Provider>
	);

}


export { AuthProvider, AuthContext };
