import  React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../ui/sidebar';
import TopNav from '../ui/top-nav';
import { AuthContext } from "../auth";

// Icon imports
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { parseMDate, formatDateTime } from "../types";
import { Alert, Box, CircularProgress, Snackbar, Switch, Typography } from '@mui/material';



export default function PendingBookings() {


	const authInfo = useContext(AuthContext);
	if(!authInfo) throw new Error("Invalid auth context.");
	const userData = authInfo.userData;

	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
		getId();

	}, [authInfo.fetchingAuth]);

	
	const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [notifyStatus, setNotifyStatus] = useState<"success" | "error" | null>(null);
    const [pendingChecked, setPendingChecked] = useState<boolean>(userData?.account_data.booking_request_notify || false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");


	function getId() {
		
		// Retrieve id from browser cookies
		let cookieValue = document.cookie.split(';').find(item => item.trim().startsWith('account_id='));
		const id = cookieValue ? cookieValue.split('=')[1] : "";
		
		setId(id);
	}


	function formatPendingBooking(pendingBooking: any) {

		return (
			<li className="flex flex-row justify-evenly pt-4 pb-4">
				<div className="">
					<h1 className="font-bold">{
                        formatDateTime(parseMDate(pendingBooking["appt_date"]))
                    }</h1>
					<h2>{pendingBooking["first_name"]} {pendingBooking["last_name"]}</h2>
					<h2>{pendingBooking["phone"]}</h2>
				</div>

				<div className="bg-slate-100 rounded-xl p-2 ">
					<h1>Notes:</h1>
					<p>{pendingBooking["general_info"]}</p>
				</div>

				<div className="flex flex-col">
					<button className="bg-slate-200 hover:bg-[#d2fcca] transition-all rounded-full w-8 h-8 mb-2" onClick={async () => {
                        setLoading(true);
						// Notify server
						const r = await fetch(`/accept?id=${pendingBooking._id.$oid}`, {
							method: "POST",
						});
                        setLoading(false);
                        
                        if (!r.ok) {
                            // Alert user
                            setSnackbarOpen(true);
                            setErrorMessage("There was an error accepting the pending booking request.");
                        }

						// Fetch new data
						if(authInfo) authInfo.fetchUserData();
					}}>
                        <DoneIcon />
                    </button>

					<button className="bg-slate-200 hover:bg-[#fccaca] transition-all rounded-full w-8 h-8" onClick={async () => {
                        setLoading(true);
						// Notify server
						const r = await fetch(`/deny?id=${pendingBooking._id.$oid}`, {
							method: "POST",
						});
                        setLoading(false);

                        if (!r.ok) {
                            // Alert user
                            setSnackbarOpen(true);
                            setErrorMessage("There was an error denying/deleting the pending booking request.");
                        }

						// Fetch new data
						if(authInfo) authInfo.fetchUserData();
					}}>
                        <CloseIcon />
                    </button>

				</div>
			</li>
		);
	}

	function pendingBookingList(): Array<JSX.Element> {
		let arr = new Array<JSX.Element>();
		
		if(!userData) return arr;

		arr.push(<hr />); // Add top line
		userData.pending_bookings.forEach((b: any) => {
			arr.push(formatPendingBooking(b))
			arr.push(<hr />);
		});
		
		return arr;
	}



	return (
	    <div>
            <Sidebar />
            <TopNav />

            <main className="md:ml-64 p-8 pt-28">

            	<h1 className="text-heading text-2xl pb-8 text-center md:text-left">Manage Booking Requests</h1>

                <div className="bg-white w-full m-auto md:pl-8 md:pr-8 pt-8 justify-center content-center rounded-md pb-8">


	    			<div className="bg-slate-100 w-fit p-8 pt-2 pb-2 md:rounded-xl m-auto ">
	    				<span className="font-bold">Your Booking Page Link: </span> <a className="underline block md:inline" href={`https://startbase.site/new-booking?b=${id}`}>{`https://startbase.site/new-booking?b=${id}`}</a>
	    				<hr className="mt-4"/>
	    				<p className="md:p-6 pt-4">
	    					You can provide this link to customers, and they can directly request bookings through it. The requests will appear here. Once approved the bookings will automatically enter the system.
	    				</p>
	    				<hr className="mb-4"/>
	    			</div>

            
	    			<h1 className="text-xl font-semibold mt-6 text-center md:text-left">Currently Pending Bookings</h1>

                    <div className="flex items-center">
                        <Switch 
                            color="primary"
                            checked={pendingChecked}
                            onChange={async (x: React.ChangeEvent<HTMLInputElement>) => {
                                const checked = x.target.checked;
                                setPendingChecked(checked);

                                const body = {
                                    notify: checked
                                };

                                const res = await fetch("/api/settings/set-request-notify", {
                                    method: "post",
                                    headers: {"Content-Type": "application/json"},
                                    body: JSON.stringify(body)
                                });
                                
                                if(res.ok) {
                                    setNotifyStatus("success");
                                } else {
                                    setNotifyStatus("error");
                                }

                                await authInfo.fetchUserData();
                                setPendingChecked(userData?.account_data.booking_request_notify || checked);
                            }}
                        />
                        <Typography className="text-main">
                            Receive SMS notifications for new booking requests?
                        </Typography>
                    </div>

                    <Snackbar 
                        open={!!notifyStatus}
                        autoHideDuration={6000}
                        onClose={() => setNotifyStatus(null)}
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    >
                        <Alert
                            severity={notifyStatus || "error"}
                        >
                            {
                                notifyStatus === "error" ? 
                                "There was an error updating your notification preferences."
                                :
                                "Successfully updated your notification preferences."
                            }
                        </Alert>
                    </Snackbar>
                    

	    			<div className="px-8 md:px-0">
	    				{pendingBookingList().length === 0 && <h2 className="mt-6 text-start">You don't have any currently pending requests!</h2>}
	    			</div>


	    			<ul className="m-auto md:w-1/2">
	    				{pendingBookingList()}
	    			</ul>

                    {loading && ( // Conditionally render the overlay
                      <Box
                        sx={{
                          position: 'absolute', // Full-screen overlay
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                          zIndex: 1000, // Ensure it's on top
                        }}
                      >
                        <CircularProgress size={60} sx={{color: "white"}}/> {/* Larger progress indicator */}
                      </Box>
                    )}

	    		</div>

                {/* Generic error notification snackbar */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => {setSnackbarOpen(false)}} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

	    	</main>
	    </div>
	);
}
