import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  CircularProgress,
  Alert,
  Paper,
  Snackbar,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { PhoneInput } from 'react-international-phone';
import dayjs from 'dayjs';

const BookingRequestPage = () => {
  const params = new URLSearchParams(window.location.search);
  const businessId = params.get("b") || "";
  const [businessName, setBusinessName] = useState("");
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [time, setTime] = useState<dayjs.Dayjs | null>(dayjs());
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");


  const [loading, setLoading] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await fetch(`/get-business-name?id=${businessId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const name = await response.text();

        // Prevent weird results on development server
        if(name.startsWith("<!DOCTYPE")) {
            setBusinessName("Test"); 
            return;
        }
        setBusinessName(name);
      } catch (err: any) {
        console.error("Error fetching business name:", err);
      } finally {
        setLoading(false);
      }
    };

    if (businessId) {
      fetchBusinessName();
    } else {
      setLoading(false);
    }

  }, [businessId]);

  const handleSnackbarClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!date || !time) {
        throw new Error("Please select a date and time.");
      }
      const formattedTime = time.format('HH:mm');
      const bookingData = {
        date: date.toDate(),
        time: formattedTime,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        notes: notes,
        business_id: businessId,
      };

      const response = await fetch("/request-booking", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bookingData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      setSnackbarSeverity('success');
      setSnackbarMessage('Booking request sent successfully!');
      setSnackbarOpen(true);
      setFirstName("");
      setLastName("");
      setPhone("");
      setDate(dayjs());
      setTime(dayjs());
    } catch (err: any) {
      console.error("Error requesting booking:", err);
      setSnackbarSeverity('error');
      setSnackbarMessage(err.message || "Failed to request booking. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const formattedDate = date ? date.format('DD/MM/YYYY') : '';
  const formattedTime = time ? time.format('HH:mm') : '';

  return (
    <Box sx={{
      minHeight: '100vh', // Ensure full viewport height
      backgroundColor: '#e0f2f7', // A light blue background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 4,
    }}
    >
        <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}> {/* Increased max width */}
          <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: '#f8fafc' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}> {/* Main form area */}
                <Typography variant="h4" align="center" gutterBottom sx={{ color: '#2563eb', fontWeight: 'bold', mb: 3 }}>
                  Booking Request for {businessName}
                </Typography>
                {!businessName && loading && <CircularProgress sx={{ display: "block", margin: "0 auto" }} />}
                {businessName && !loading && (
                  <Typography variant="h6" align="center" gutterBottom sx={{ color: '#1d4ed8', mb: 3 }}>{businessName}</Typography>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <DatePicker
                        label="Date"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        slotProps={{ textField: { fullWidth: true, variant: "outlined", InputLabelProps: { shrink: true } } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TimePicker
                        label="Time"
                        value={time}
                        onChange={(newValue) => setTime(newValue)}
                        slotProps={{ textField: { fullWidth: true, variant: "outlined", InputLabelProps: { shrink: true } } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="First Name" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} required variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Last Name" fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} required variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Optional Notes/Requests" multiline rows={3} fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                      <PhoneInput
                        defaultCountry="au" value={phone} onChange={setPhone}
                        inputStyle={{ width: '100%', border: "1px solid #ced4da", borderRadius: "4px", padding: "8.5px 14px", outline: "none", boxSizing: 'border-box' }} required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" fullWidth onClick={handleSubmit} disabled={loading} sx={{ backgroundColor: '#2563eb', '&:hover': { backgroundColor: '#1d4ed8' }, padding: 1.5, fontSize: '1rem', fontWeight: 'bold' }}>
                        {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Submit Booking Request"}
                      </Button>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}> {/* Summary Panel */}
                <Paper elevation={2} sx={{ padding: 3, height: '100%', backgroundColor: "#eef2ff"}}> {/* Added background colour */}
                  <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold', color: '#2563eb'}}>Booking Summary</Typography>
                  <Typography><strong>Business:</strong> {businessName}</Typography>
                  <Typography><strong>Date:</strong> {formattedDate}</Typography>
                  <Typography><strong>Time:</strong> {formattedTime}</Typography>
                  <Typography><strong>Name:</strong> {firstName} {lastName}</Typography>
                  <Typography><strong>Phone:</strong> {phone}</Typography>
                  <Typography><strong>Additional Notes:</strong> {notes}</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Container>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
    </Box>
  );

};

export default BookingRequestPage;
