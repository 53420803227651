
import Sidebar from "../ui/sidebar";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import TextField from '@mui/material/TextField';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, CircularProgress, Backdrop, Modal, FormControl, FormHelperText, Alert, Box, Typography, Snackbar, IconButton } from '@mui/material';
import BookingList from './booking-log';
import TopNav from '../ui/top-nav';
import { AuthContext } from "../auth";
import { Loupe, UploadFile } from '@mui/icons-material';
import CloseIcon from "@mui/icons-material/Close";



// Contact profile imports
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from "react";


export default function Bookings() {

	const authInfo = useContext(AuthContext);

    const [dateTime, setDateTime] =       useState<Dayjs | null>(null);
    const [bookingDate, setBookingDate] = useState<Dayjs | null>(dateTime ? dayjs(dateTime) : null);
    const [bookingTime, setBookingTime] = useState<Dayjs | null>(dateTime ? dayjs(dateTime) : null);
    const [hasAdjustedDate, setHasAdjustedDate] = useState<boolean>(false);
    const [hasAdjustedTime, setHasAdjustedTime] = useState<boolean>(false);

    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [notes, setNotes] = useState("");

    const [addingBooking, setAddingBooking] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);

    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [submissionError, setSubmissionError] = useState<string | null>(null);


    const [file, setFile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info'>('info'); // Default to info


	if(!authInfo) throw new Error("Invalid auth context.");
	const userData = authInfo.userData;


	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);


    /* ========================= DATE CHANGE CODE ========================= */
    const handleDateChange = (newDate: any) => {
      setBookingDate(newDate);
      setHasAdjustedDate(true);
      updateDateTime(newDate, bookingTime);
    };

    const handleTimeChange = (newTime: any) => {
      setBookingTime(newTime);
      setHasAdjustedTime(true);
      updateDateTime(bookingDate, newTime);
    };

    const updateDateTime = (date: Dayjs | null, time: Dayjs | null) => {
      if (date && time) {
        const combinedDateTime = date.set('hour', time.get('hour')).set('minute', time.get('minute')).set('second', 0);
        setDateTime(combinedDateTime);
      } else {
        setDateTime(null); // Or handle it as needed (e.g., set to a default date/time)
      }
    };

    /* ======================= END DATE CHANGE CODE ======================= */

    /* ========================= FILE UPLOAD CODE ========================= */
    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event: any) => {
      event.preventDefault(); // Prevent default form submission
      if (!file) {
        setSnackbarSeverity('error');
        setSnackbarMessage("Please select a file.");
        setSnackbarOpen(true);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      console.log("Attempting to upload form");
      console.log(formData);

      try {
        const response = await fetch('/import', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          const errorData = await response.text();
          throw new Error(errorData || `HTTP error! status: ${response.status}`);
        }

        setSnackbarSeverity('success');
        setSnackbarMessage('File uploaded successfully!');
        setSnackbarOpen(true);
        //onUploadComplete(); // Notify parent component
        setUploadingFile(false);

      } catch (error: any) {
        console.error("File upload error:", error);
        setSnackbarSeverity('error');
        setSnackbarMessage(error.message || "File upload failed. Please try again.");
        setSnackbarOpen(true);
      }
    };

    const handleSnackbarClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    /* ======================= END FILE UPLOAD CODE ======================= */

    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />

            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>

            <TopNav/>

            <main className="md:ml-64 md:p-8 pt-28">

                <h1 className="text-heading text-2xl pl-8 md:pl-0 pb-8">Contact Management</h1>

                <div className="bg-white w-full m-auto pt-0 flex flex-col justify-center content-center rounded-md">
                    <div className="p-8">
                        <h1 className="text-[#2196F3] text-xl mb-4">Booking Details</h1>

						{/* For mobile layout */}
                        <div className="block md:hidden mb-4">
                            <TextField label="Search" placeholder="Name, email, etc..." sx={{width: "100%"}}/>
                        </div>

						
                        <div className="flex flex-col md:flex-row">
                            <div className="hidden md:block mr-4">
                                <TextField label="Search" placeholder="Name, email, etc..."/>
                            </div>

                            <button
							type="button"
							className="border-2 w-full md:w-fit rounded-md pl-4 pr-4 border-primary text-primary transition-all text-md hover:bg-[#0000000F] ml-auto pt-4 pb-4 md:pt-0 md:pb-0 md:mr-4 mb-4 md:mb-0"
							onClick={() => setUploadingFile(true)}>
								<UploadFile/> <span className="block sm:inline">UPLOAD BOOKINGS FROM FILE</span>
							</button>
							

                            <button
							type="button"
							onClick={() => setAddingBooking(true)}
                            className="bg-primary w-full md:w-fit pl-4 pr-4 rounded-md text-white transition-all text-md hover:bg-blue-font pt-4 pb-4 md:pt-0 md:pb-0">
							 	<Loupe/> <span className="block sm:inline">ADD NEW BOOKING</span>
                            </button>
                        </div>

                    </div>
                    <BookingList bookings={userData?.booking_list || []}/>
                </div>

                {/* New booking form */}
                <Modal 
                    open={addingBooking} 
                    onClose={() => setAddingBooking(false)}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="p-4 md:p-10 pb-6 rounded-md bg-white w-5/6 md:w-2/3 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                            <h1 className="text-2xl font-bold text-center md:text-left text-heading mb-12">Add New Booking</h1>

                            <form action="/booking" method="post" encType="application/x-www-form-urlencoded" className="w-full" onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
								// Prevent initial form submission
								e.preventDefault();
                                setFormErrors({});
                                setSubmissionError(null);

                                const errors: { [key: string]: string } = {};

                                if (!firstName) {
                                    errors.firstName = "First name is required";
                                }
                                if (!surname) {
                                    errors.surname = "Last name is required";
                                }
                                if (phone.length < 5) {
                                    errors.phone = "Phone number is required";
                                }
                                if (!hasAdjustedDate || !hasAdjustedTime) {
                                    errors.dateTime = "Date and time are required";
                                }

                                if (Object.keys(errors).length > 0) {
                                    setFormErrors(errors);
                                    return; // Stop submission
                                }

								setAddingBooking(false);
								setLoading(true);

                                let data = {
                                    booking_type: "log",
                                    notes: notes,
                                    first_name: firstName,
                                    surname: surname,
                                    date: dateTime?.toISOString(),
                                    phone: phone,
                                    email: email
                                };

                                console.log(data);

                                try {
                                    let res = await fetch('/booking', {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body: JSON.stringify(data)
                                    });

                                    if (!res.ok) {
                                        const errorData = await res.json();
                                        throw new Error(errorData.message || "Failed to create booking");
                                    }

                                    authInfo.fetchUserData();
                                } catch (error: any) {
                                    console.error("Error creating booking:", error);
                                    setSubmissionError(error.message || "An error occurred during booking.");
                                } finally {
                                    setLoading(false);
                                }
							}}>
                                <input type='hidden' value="log" name="type"></input>
                                {submissionError && <Alert severity="error">{submissionError}</Alert>}
                                <div className='lg:flex justify-between'>
                                  <div className='flex flex-col justify-center mr-4 lg:w-1/2'>
                                    <h1 className='text-lg text-heading'>Personal Details</h1>

                                    <div className='flex flex-row justify-between'>
                                        <TextField
                                            label="First Name"
                                            variant='outlined'
                                            margin="normal"
                                            className="w-[48%]"
                                            onChange={(e: any) => setFirstName(e.target.value)}
                                            error={!!formErrors.firstName} // Show error state
                                            helperText={formErrors.firstName} // Display error message
                                        />
                                        <TextField
                                            label="Last Name"
                                            variant='outlined'
                                            margin="normal"
                                            className="w-[48%]"
                                            onChange={(e: any) => setSurname(e.target.value)}
                                            error={!!formErrors.surname}
                                            helperText={formErrors.surname}
                                        />
                                    </div>

                                    <h2 className="mt-2 text-heading text-md mb-2">Contact Methods</h2>
                                    <FormControl error={!!formErrors.phone}> {/* Wrap PhoneInput in FormControl */}
                                        <PhoneInput
                                            defaultCountry="au"
                                            value={phone}
                                            onChange={(value) => { setPhone(value); }}
                                            className="mb-2 z-10 w-full"
                                        />
                                        {formErrors.phone && <FormHelperText>{formErrors.phone}</FormHelperText>} {/* Display error */}
                                    </FormControl>
                                    <TextField type="email" label="Email" margin="normal" name="email" placeholder="Email" InputLabelProps={{ shrink: true }} onChange={(e) => setEmail(e.target.value)}/>
                                  </div>

                                  <div className='lg:ml-4 bg-slate-100 p-8 rounded-xl lg:w-1/2'>
                                    <h1 className='text-heading text-lg mb-2'>Booking Time and Date</h1>
                                    <div className="flex flex-col md:flex-row gap-2 w-full">
                                      <FormControl error={!!formErrors.dateTime} className="w-full">
                                        <DatePicker
                                          label="Date"
                                          value={bookingDate}
                                          onChange={handleDateChange}
                                          slotProps={{ textField: { fullWidth: true, variant: "outlined", InputLabelProps: { shrink: true } } }}
                                        />
                                        {formErrors.dateTime && <FormHelperText>{formErrors.dateTime}</FormHelperText>}
                                      </FormControl>
                                      <FormControl error={!!formErrors.dateTime} className="w-full">
                                        <TimePicker
                                          label="Time"
                                          value={bookingTime}
                                          onChange={handleTimeChange}
                                          slotProps={{ textField: { fullWidth: true, variant: "outlined", InputLabelProps: { shrink: true } } }}
                                        />
                                        {formErrors.dateTime && <FormHelperText>{formErrors.dateTime}</FormHelperText>}
                                      </FormControl>
                                    </div>
                                    <h1 className='text-heading text-lg mb-2'>Additional Notes</h1>
                                    <TextField multiline label="Additional Notes" placeholder="Type additional notes here" minRows={2} name='notes' className='bg-white w-full' onChange={(e) => setNotes(e.target.value)}/>
                                  </div>
                                </div>

                                <div className='flex flex-row justify-end gap-4 mt-6'>
                                  <Button type='button' variant='outlined' onClick={() => setAddingBooking(false)}>Cancel</Button>
                                  <Button type='submit' variant='contained' disabled={loading}>Confirm Booking</Button>
                                </div>

                            </form>

                        </div>
                    </LocalizationProvider>
                </Modal>

                {/* File upload modal
                <Modal open={uploadingFile} onClose={() => {setUploadingFile(false)}} >
                    <div className="bg-white w-1/2 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 p-4 rounded-md">
                        <form 
                            action="/import" 
                            method="post" 
                            encType="multipart/form-data"
                            className="flex flex-col justify-center">

                            <h1 className='font-semibold text-heading'>Upload CSV Booking</h1>
                            <p className="text-main mt-4">Startbase will attempt to automatically extract information from booking logs, improving the software transition experience.</p>
                            <p className="text-main mt-4">As this process is completely automatic, some information may be missed - but we'll notify you if anything can't be processed correctly.</p>

                            <input name="file" required type="file" className="w-1/2 self-center mb-4 mt-16"></input>
                            <Button variant="outlined" className="w-1/2 self-center" type="submit">Submit</Button>
                        </form>
                    </div>
                </Modal>
                */}

                <Modal open={uploadingFile} onClose={() => setUploadingFile(false)}>
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {xs: '95%', md: '50%'}, // Use percentage for responsiveness
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                  }}>
                    <IconButton
                      aria-label="close"
                      onClick={() => setUploadingFile(false)}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit} className="flex flex-col justify-center">
                      <Typography variant="h5" className='font-semibold text-heading' gutterBottom>Upload CSV Booking</Typography>
                      <Typography variant="body1" className="text-main mt-2">Startbase will attempt to automatically extract information from booking logs, improving the software transition experience.</Typography>
                      <Typography variant="body1" className="text-main mt-2">As this process is completely automatic, some information may be missed - but we'll notify you if anything can't be processed correctly.</Typography>

                      <input name="file" required type="file" className="w-full mt-4 mb-4" onChange={handleFileChange} />
                      <Button variant="outlined" className="w-full mt-4" type="submit">Submit</Button>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                  </Box>
                </Modal>

            </main>

        </div>
    );
}
