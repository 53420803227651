import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams, GridRowParams, GridCallbackDetails, MuiEvent} from '@mui/x-data-grid';
import { AuthContext }  from "../auth";

import { parseMDate } from "../types";


export default function BookingList( { bookings } : { bookings : Array<any> } ) {

	const [deleting, setDeleting] = useState(false);
	const authInfo = useContext(AuthContext);
	const navigate = useNavigate();
	
	if(!authInfo) throw new Error("Invalid auth context.");


    const columns: GridColDef[] = [
        { 
            field: 'fullName',
            headerName: 'Full Name',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => 
                `${params.row.first_name || ''} ${params.row.last_name || ''}`,
        },
        {
            field: 'local_datetime',
            headerName: 'Booking Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
                return parseMDate(params.row.appt_date).toString().split("GMT")[0];
            },
        },
        {
            field: 'phone',
            headerName: 'Mobile',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'general_info',
            headerName: 'Appointment Notes',
            flex: 1,
        },
		{
			field: 'deleteButton',
			headerName: "Delete",
			flex: 1,
			renderCell: (params: any) => {
				return (
					<Button onClick={async (e: any) => {

                        // Prevent click event from propagating to parent row
                        e.stopPropagation();


						// Start loading animation
						setDeleting(true);

						const bookingID: string = params.row['_id']['$oid'];
						const res = await fetch('/delete', {
							method: "post",
							headers: {
								"Content-Type": "application/json"
							},
							body: JSON.stringify({"_id": bookingID})
						});
                        if (!res.ok) {
                            alert("There was an error deleting the booking. Try again later");
                        }

						// Now force fetch data
						authInfo.fetchUserData();
                        setDeleting(false);
					}}>x</Button> as React.ReactNode
				);
			}
		}

    ];

	function openContactProfile(params: GridRowParams, 
								event: MuiEvent<React.MouseEvent<HTMLElement>>, 
								details: GridCallbackDetails) {

		navigate(`/contact-profile?id=${params.row._id.$oid}`);
	}

    return (
        <div className="p-4">
			<Backdrop open={deleting} className="text-center z-50">
				<CircularProgress />
			</Backdrop>

            <DataGrid
                rows={bookings}
                columns={columns}
                getRowId={(row) => row['_id']['$oid']}
				onRowClick={openContactProfile} 
				className="cursor-pointer"
            ></DataGrid>
        </div>
    );
}


