// React Imports
import * as React from 'react';
import { useState } from 'react';

import MobileMenu from "./mobile-menu";

// Icons
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';

import { FullLogoBlack } from '../ui/logo';

// MUI Components
import { Box, Typography } from '@mui/material';
import ContactForm from '../ui/contact-form';
import { BASIC_FEATURES, PRO_FEATURES } from '../types';
import { motion } from 'framer-motion';
import { ArrowRight } from '@mui/icons-material';


export default function Landing() {


	// image imports
	const logo = require("../images/logo-full.svg");
	const clientRetention = require("../images/client-retention.png");

	const overview = require("../images/overview.png");
	const bookings = require("../images/new-booking.png");
	const ai = require("../images/ai-analysis.png");
	const reviews = require("../images/reminder.png");
	const marketing = require("../images/marketing.png");

      
	const ads = require("../images/generalAds 1.png");
    const messages = require("../images/messages.png");


	// Service Description Component Array
    const serviceArray = [
      {
        id: 1,
        name: "Comprehensive Data Overview",
        description: "Enhance campaign management with our meticulously designed dashboard, providing instant access to all data in a visually appealing format for optimized control and efficiency.",
        imageUrl: overview,
      },
      {
        id: 2,
        name: "No more no-shows",
        description: "Log bookings and appointments on our simple web-based interface, we'll automatically remind customers when their appointment is coming up.",
        imageUrl: bookings,
      },
      {
        id: 3,
        name: "Your Analytics - Simplified",
        description: "We pass your online performance analytics through an AI-based interpretation algorithm to bring a mix of raw data and curated insights to you.",
        imageUrl: ai,
      },
      {
        id: 4,
        name: "Boost Your Reviews",
        description: "We'll send out automatic personalized reminders to your customers with a 'one-click' link to leave a Google Review - launching your business up the search recommendation ladder.",
        imageUrl: reviews,
      },
      {
        id: 5,
        name: "Flexible Marketing to Clients",
        description: "Send easily customisable messages to your entire clientele with the freedom to schedule them to repeat whenever you want.",
        imageUrl: marketing,
      },
      //{
      //  id: 6,
      //  name: "AI-Assisted Ad Creation",
      //  description: "Utilise Google's AI-drive Dynamic Search Ad system to quickly and easily supplement your advertising efforts right from the Startbase dashboard.",
      //  imageUrl: ads,
      //},
      {
        id: 6,
        name: "Private Messaging Platform",
        description: "Send and receive messages from any of your customers right from the Startbase dashboard.",
        imageUrl: messages
      }
    ];


    const [selectedService, setSelectedService] = useState<number | null>(null);
    const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
    const [showFullDescription, setShowFullDescription] = useState(-1);


	function scrollToContact() {
		const el = document.getElementById("contact-form");
		if(!el) return;
		window.scroll({
			top: el.getBoundingClientRect().top,
			behavior: "smooth"
		});
	}

    return (
        <div className="pt-12 font-lato bg-white w-screen overflow-x-hidden">
            {/* Header */}
            <div className="w-full flex flex-row justify-between items-center pl-6 pr-6 md:pr-36 md:pl-36 py-4"> {/* Added py-4 for vertical padding */}
              <div className="font-lato flex flex-row items-center">
                <FullLogoBlack />
              </div>
            
              <div className="hidden lg:flex items-center space-x-8"> {/* Added space-x-8 for spacing between buttons */}
                <button
                  className="text-[#565656] hover:underline text-lg md:text-xl" // Larger text
                  onClick={scrollToContact}
                >
                  Contact Us
                </button>

                <button
                    className="group inline-flex items-center gap-2 px-6 sm:px-8 py-3 text-base sm:text-lg font-semibold text-white bg-purple-600 rounded-lg shadow-lg hover:bg-purple-700 transition-colors"
                    onClick={() => (window.location.href = "/login")}
                >
                  Log In &nbsp; <ArrowRight className="w-5 h-5"/>
                </button>
              </div>
            
              <MobileMenu scrollToContact={scrollToContact} />
            </div>

            <div className="relative min-h-[80vh] lg:min-h-screen bg-gradient-to-b to-purple-50 from-white overflow-hidden ">
                {/* Background decoration */}
                <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0.6))] -z-10" />
                
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 lg:pt-32">
                  <div className="flex gap-12 items-center justify-between">
                    {/* Left content */}
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                      className="space-y-12 lg:space-y-8"
                    >
                      <div className="space-y-6">
                        <h1 className="text-4xl sm:text-5xl lg:text-7xl font-extrabold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600 pb-2">
                          Startbase Management
                        </h1>
                        
                        <p className="text-lg sm:text-xl lg:text-2xl text-gray-600 max-w-xl">
                          Enhance the efficiency and productivity of your business operations through the integration of streamlined automation solutions.
                        </p>
                      </div>
                      
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="group inline-flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white bg-purple-600 rounded-lg shadow-lg hover:bg-purple-700 transition-colors"
                        onClick={scrollToContact}
                      >
                        Request a Demo
                        <ArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
                      </motion.button>
                    </motion.div>
                    
                    {/* Right content - Image */}
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.8, delay: 0.2 }}
                      className="hidden lg:block relative"
                    >
                      <div />
                            <svg width="319" height="462" viewBox="0 0 319 462" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 303.718V376.906C0 381.069 2.37465 384.865 6.14082 386.73L154.246 459.918C157.392 461.481 161.038 461.389 164.138 459.852L164.169 459.918L312.274 386.73C316.04 384.865 318.415 381.069 318.415 376.906V230.543C318.415 226.38 316.04 222.585 312.274 220.72L206.247 168.334H307.298C313.439 168.334 318.4 163.422 318.4 157.355V84.167C318.4 80.004 316.025 76.2086 312.259 74.3438L164.184 1.15567C162.611 0.380846 160.917 0 159.207 0C157.498 0 155.835 0.472775 154.277 1.2476L154.231 1.1688L6.14082 74.3438C2.37465 76.2086 0 80.004 0 84.167V230.53C0 234.693 2.37465 238.489 6.14082 240.353L112.168 292.739H11.1019C4.97618 292.739 0 297.651 0 303.718ZM170.324 432.313V321.474L282.478 376.893L170.324 432.313ZM148.106 28.7342V139.574L35.9525 84.167M159.207 169.621L282.478 230.53L159.207 291.439L35.9374 230.53L159.207 169.621Z" fill="url(#paint0_linear_5136_31742)"/>
                                <path style={{mixBlendMode: "multiply"}} opacity="0.65" d="M148.107 314.695L112.169 292.75H18.1816V314.695H148.107Z" fill="url(#paint1_linear_5136_31742)"/>
                                <path style={{mixBlendMode: "multiply"}} opacity="0.65" d="M201.332 295.17L296.212 248.287V237.321L282.478 230.531L180.837 280.75L201.332 295.17Z" fill="url(#paint2_linear_5136_31742)"/>
                                <path style={{mixBlendMode: "multiply"}} opacity="0.65" d="M170.327 146.375L206.265 168.333H300.237V146.375H170.327Z" fill="url(#paint3_linear_5136_31742)"/>
                                <path style={{mixBlendMode: "multiply"}} opacity="0.65" d="M35.9514 230.526L137.577 180.306L117.083 165.887L22.2178 212.757V223.736L35.9514 230.526Z" fill="url(#paint4_linear_5136_31742)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_5136_31742" x1="-39.7036" y1="388.292" x2="418.875" y2="-94.1328" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#00D8DB"/>
                                        <stop offset="1" stop-color="#F900FF"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_5136_31742" x1="121.759" y1="300.301" x2="61.1906" y2="307.437" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#6F3F9D"/>
                                        <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_5136_31742" x1="278.924" y1="242.167" x2="215.373" y2="288.824" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#6F3F9D"/>
                                        <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_5136_31742" x1="196.66" y1="160.781" x2="257.228" y2="153.646" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#6F3F9D"/>
                                        <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                    <linearGradient id="paint4_linear_5136_31742" x1="17.5441" y1="239.626" x2="64.3334" y2="196.748" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#6F3F9D"/>
                                        <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                    </motion.div>
                  </div>
                </div>
                
                {/* Decorative bottom wave */}
                <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-b from-transparent to-purple-50/10" />
            </div>

			{/* Features */}
            <div className="bg-[#F5FEFE] mt-0 w-screen h-fit pt-8 pb-16 relative z-10">
              <Typography variant="h2" align="center" gutterBottom className="text-[#464748]" sx={{fontSize: {xs: '3rem', md: '5rem'}, fontWeight: "bold"}}> {/* Changed to Typography */}
                Features
              </Typography>

              <div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 pl-6 pr-6 lg:pr-24 lg:pl-24 mt-24 gap-16 text-center">
                {[
                  { icon: <MarkChatUnreadOutlinedIcon fontSize="large"/>, title: "Automated Reminders", description: "Automatically send reminders to your clients." },
                  { icon: <PsychologyOutlinedIcon fontSize="large"/>, title: "AI Analytics Reports", description: "Improve your online prescence and growth." },
                  { icon: <LanguageOutlinedIcon fontSize="large"/>, title: "Simple Booking Record", description: "Never lose track of bookings again." },
                  { icon: <RateReviewOutlinedIcon fontSize="large"/>, title: "Centralized Marketing Portal", description: "Handle marketing messages with ease." },
                  { icon: <AdsClickOutlinedIcon fontSize="large"/>, title: "Review Funnelling", description: "Track your increase in Google Reviews." },
                  //{ icon: <LoupeOutlinedIcon fontSize="large"/>, title: "AI Assisted Ad Campaigns", description: "Efficiently craft Google Ads with AI support." },
                  { icon: <LoupeOutlinedIcon fontSize="large"/>, title: "Private Messaging Platform", description: "Send individual SMS messages right from your bookings page." },
                ].map((feature, index) => (
                  <div className="text-[#464748]" key={index}>
                    {feature.icon}
                    <Typography variant="h5" gutterBottom className="font-semibold" sx={{fontSize: {xs: '1.25rem', lg: '1.5rem'}}}> {/* Changed to Typography */}
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: {xs: '1rem', lg: '1.125rem'}}}> {/* Changed to Typography, increased font size */}
                      {feature.description}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
			

			{/* Client Retention */}
            <Box className="py-24 px-6 md:px-36 w-full"> {/* Added w-full to ensure it takes the full width */}
              <Box className="max-w-7xl mx-auto flex flex-col lg:flex-row-reverse items-center justify-center lg:justify-between gap-16"> {/* Centered content */}

                <Box className="lg:w-1/2 mt-8 lg:mt-0 text-center lg:text-left"> {/* Text container, text left on large screens */}
                  <Typography variant="h2" className="font-extrabold text-[#464748]" gutterBottom sx={{ fontSize: { xs: '2rem', md: '3rem', lg: '4rem' }, fontWeight: "bold"}}>
                    Boost your client retention
                  </Typography>
                  <Typography variant="body1" className="text-[#464748]" sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' } }}>
                    Using proprietary algorithms and data management systems, Startbase's tailored marketing suite will help you to keep you closely connected to clientele - as well as analyzing and reporting on your digital marketing and advertising efforts.
                  </Typography>
                </Box>

                <Box className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center lg:justify-end"> {/* Image container, justify end on large screens */}
                  <img
                    src={clientRetention} 
                    alt="Client Retention" 
                    className="object-contain w-full max-w-md lg:max-w-full" 
                  /> 
                </Box>

              </Box>
            </Box>

			{/* Service Heading */}
			<div className="w-screen bg-[#D1D3D4]">
				<div className="bg-[url('./images/lamps.png')] md:h-[236px] bg-cover md:bg-contain bg-no-repeat bg-center relative"> {/* Added relative for absolute positioning */}
					<h1 className="text-4xl md:text-6xl text-[#464748] font-bold text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:pt-0"> {/* Updated styling and absolute positioning */}
						About our Services
					</h1>
				</div>
			</div>


            {/* Service Overview */}
            <section className="bg-[#F3F3F9] py-12">
              <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Our Services</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"> 
                {serviceArray.map((service) => (
                  <div
                    key={service.id}
                    className="bg-white rounded-lg shadow-md p-6 relative transition-shadow duration-300 hover:shadow-lg transition-transform duration-500 ease-in-out hover:scale-105"
                  >

                    <img
                      src={service.imageUrl}
                      alt={service.name}
                      className="w-full object-cover mb-4 rounded-lg ring-1 ring-gray-200 transition-all duration-300 ease-in-out "
                    />
                      
                      
                    <h3 className="text-xl font-semibold mb-2 text-gray-800 transition-colors duration-300 ease-in-out ">
                      {service.name}
                    </h3>
                    <p className="text-gray-600 text-justify">{service.description}</p>
                  </div>
                ))}
              </div>
            </section>


			{/* Pricing section */}
			<div className="w-screen bg-[#D1D3D4] p-4 md:p-8 md:pb-12">
                <Typography variant="h2" align="center" gutterBottom className="text-[#464748]" sx={{fontSize: {xs: '3rem', md: '5rem'}, fontWeight: "bold"}}> {/* Changed to Typography */}
                    Pricing
                </Typography>
                <Typography variant="h4" align="center" sx={{marginBottom: "4rem", marginTop: "4rem"}}>
                    Save hours in booking management and promotional marketing,
                    <br />
                    focus on running your business.
                </Typography>
                <div className="flex flex-col-reverse md:flex-row justify-center mt-8">

                    {/* Basic Tier */}
                    <div className="relative rounded-xl bg-white p-8 mx-4 shadow-xl transform hover:scale-102 transition-all duration-300">
                      {/* Subtle border overlay */}
                      <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-gray-200 via-gray-300 to-gray-200 p-[2px] -z-10">
                        <div className="absolute inset-0 bg-white rounded-xl" />
                      </div>

                      {/* Content */}
                      <div className="relative">
                        <h1 className="text-center font-semibold text-2xl mb-4 text-gray-700">
                          Basic
                        </h1>

                        <div className="text-center">
                          <h2 className="text-3xl font-extrabold text-gray-800">
                            $230 / Month
                          </h2>
                        </div>

                        {/* Included Features */}
                        <ul className="list-none mt-8 text-lg space-y-4">
                          {BASIC_FEATURES.map((feature) => (
                            <li key={feature} className="flex items-start space-x-3">
                              <span className="text-green-500 flex-shrink-0">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                              </span>
                              <span className="text-gray-700">{feature}</span>
                            </li>
                          ))}
                        </ul>

                        {/* Not Included Features */}
                        <ul className="list-none text-lg space-y-4 mt-4">
                          {PRO_FEATURES.map((feature) => (
                            <li key={feature} className="flex items-start space-x-3">
                              <span className="text-gray-300 flex-shrink-0">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                              </span>
                              <span className="text-gray-400">{feature}</span>
                            </li>
                          ))}
                        </ul>

                        <button
                          className="w-full mt-12 px-6 py-3 rounded-xl text-lg font-semibold text-white 
                                     bg-gray-600 hover:bg-gray-700
                                     transform transition-all duration-300 
                                     shadow-md hover:shadow-lg"
                          onClick={() => {window.location.href = "/subscribe?tier=0"}}
                        >
                          Join Startbase
                        </button>
                      </div>
                    </div>

                    {/* Pro tier */}
                    <div className="relative rounded-xl bg-white p-8 mx-4 shadow-2xl transform hover:scale-105 transition-all duration-300 mb-8 md:mb-0">
                      {/* Popular badge */}
                      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                        <span className="bg-[#C82AF8] text-white px-6 py-1 rounded-full text-sm font-semibold shadow-lg">
                          MOST POPULAR
                        </span>
                      </div>

                      {/* Gradient border overlay */}
                      <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-[#C82AF8] via-[#A608D6] to-[#8B0DAF] p-[3px] -z-10">
                        <div className="absolute inset-0 bg-white rounded-xl" />
                      </div>

                      {/* Content */}
                      <div className="relative">
                        <h1 className="text-center font-semibold text-2xl mb-4 bg-gradient-to-r from-[#C82AF8] to-[#8B0DAF] bg-clip-text text-transparent">
                          Premium
                        </h1>

                        <div className="text-center space-y-1">
                          <span className="text-2xl text-gray-400 line-through block">
                            $590 / Month
                          </span>
                          <h2 className="text-4xl font-extrabold">
                            $490 / Month
                          </h2>
                        </div>

                        <ul className="list-none mt-8 text-lg space-y-4">
                          {BASIC_FEATURES.concat(PRO_FEATURES).map((feature) => (
                            <li key={feature} className="flex items-start space-x-3">
                              <span className="text-[#C82AF8] flex-shrink-0">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                              </span>
                              <span>{feature}</span>
                            </li>
                          ))}
                        </ul>

                        <button
                          className="w-full mt-12 px-6 py-3 rounded-xl text-lg font-semibold text-white 
                                     bg-gradient-to-r from-[#C82AF8] to-[#8B0DAF] 
                                     hover:from-[#A608D6] hover:to-[#760B94] 
                                     transform transition-all duration-300 
                                     shadow-lg hover:shadow-xl"
                          onClick={() => {window.location.href = "/subscribe?tier=1"}}
                        >
                          Join Startbase
                        </button>
                      </div>
                    </div>
                </div>
			</div>


			{/* Contact Form */}
            <ContactForm />

			
			{/* Footer */}
			<footer className="bg-black text-white text-sm p-12">
				<div className="flex flex-row justify-center">
	  			    <span className="ml-2">©2025 Startbase Management | All rights reserved | <a href="/privacy-policy" className="hover:underline">Privacy Policy</a></span>
				</div>
			</footer>


        </div>
    );
}


