import React from 'react';
import { Typography, Box, LinearProgress } from '@mui/material';

interface PasswordStrengthIndicatorProps {
  password: string;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({ password }) => {
  const calculatePasswordStrength = (password: string) => {
    let score = 0;

    if (password.length >= 8) score += 1;
    if (password.match(/[0-9]/)) score += 1;
    if (password.match(/[A-Z]/)) score += 1;
    if (password.match(/[a-z]/)) score += 1;
    if (password.match(/[^a-zA-Z0-9]/)) score += 1;

    return score;
  };

  const getPasswordStrengthLabel = (score: number) => {
    switch (score) {
      case 0:
        return 'Very Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
      case 5:
        return 'Strong';
      default:
        return '';
    }
  };

  const passwordStrength = calculatePasswordStrength(password);
  const strengthLabel = getPasswordStrengthLabel(passwordStrength);
  const progressValue = (passwordStrength / 5) * 100;

  return (
    <> {/* Use a fragment to avoid extra div */}
      {password && (
        <Box mt={1}>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor:
                  passwordStrength <= 1 ? '#f44336' :
                  passwordStrength <= 2 ? '#ff9800' :
                  passwordStrength <= 3 ? '#ffeb3b' :
                  '#4caf50',
              },
            }}
          />
          <Typography variant="body2" color="textSecondary" mt={0.5}>
            Password Strength: {strengthLabel}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PasswordStrengthIndicator;
