import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


/* Assorted information imports */
import { MAX_MESSAGES_TO_DISPLAY } from "../inbox/inbox";


interface HelpItem {
    title: string;
    content?: string;
    notes?: string;
    children?: HelpItem[];
}

interface HelpModalProps {
    open: boolean;
    onClose: () => void;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '95%', md: '80%'},
    maxWidth: '800px',
    bgcolor: 'background.paper',
    borderRadius: "0.5rem",
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: {xs: 'column', md: 'row'},
    maxHeight: '90vh',
    overflowY: 'auto'
};

const helpData: HelpItem[] = [
    {
        title: "Overview",
        content: `The overview dashboard is the starting point for Startbase's 
                 analytics reporting, providing quick insights into several aspects 
                 of your business.`,
        children: [
            {
                title: "Booking Graph",
                content: `On the left of the page in the box titled 'Bookings' is 
                          a graph displaying the number of bookings you have 
                          logged for each day of the next week.`
            },
            {
                title: "Ad Campaign Overview",
                content: `At the bottom of the overview page is a list of your 
                          most recent Google Ads campaigns, providing an easy 
                          platform to view the key performance statistics from 
                          your advertising efforts.`,
                notes: "Requires a connected Google account."
            }
        ]
    },
    {
        title: "AI Analysis",
        content: `The AI analysis page is where we use advanced machine 
                  learning methods to background information about your business
                  (entered in the form on the left of the page) and performance 
                  statistics to produce easy-to-interpret and actionable 
                  insights and suggestions for your Google advertising efforts.`,
        notes: "Requires 'Pro' subscription and a connected Google account.",
        children: [
            {
                title: "AI Business Background Form",
                content: `You can fill out this form however you wish to best 
                          describe your business in the given categories. Feel free 
                          to experiment with the specificity of your descriptions.`,
                notes: "Requires 'Pro' subscription and a connected Google account.",
            }
        ]
    },
    {
        title: "Record New Booking",
        content: `Here you can manually log new bookings, note that this is not
                  for your customers to request booking through, rather it's so
                  you can personally enter bookings into your record. There is 
                  also a simple list of your bookings. Clicking on one of these 
                  listed bookings takes you to the customer's profile - where you 
                  can privately message them.`
    },
    {
        title: "Booking Calendar",
        content: `Here you can view your recorded bookings in a simple 
                  calendar layout.`
    },
    {
        title: "Reminders",
        content: `This page allows you to modify the messages used in your 
                  automatic booking and review reminders. The messages you type 
                  in here serve as templates that are personalised to each 
                  recipient with information like their name and booking date. 
                  A preview of what the message might look like is shown on the 
                  right, using example information.`
    },
    {
        title: "Marketing Portal",
        notes: "Requires 'Pro' subscription.",
        content: `This page allows you to send a message to your intire clientele,
                  whether as a scheduled one-off notice or repeating marketing 
                  reminders. Opening a customer's profile allows you to see whether they
                  have opted out of automatic messages, which is done by replying 'stop'
                  to any of the messages they receive.`
    },
    {
        title: "Inbox",
        content: `Your inbox is where you can view the last ${MAX_MESSAGES_TO_DISPLAY}
                  messages from all of your customers, allowing quick access to 
                  recent notifications.` 
    },
    {
        title: "Pending Bookings",
        content: `Here is where bookings requested directly by customers will appear,
                  and you can use the tick/cross buttons to either accept the booking 
                  (automatically entering it into your record) or deny it. Either way
                  the requesting customer will be automatically notified via SMS message
                  of the choice. The link that customers can use to directly request 
                  bookings is also presented on this page.`
    }
];


const HelpModal: React.FC<HelpModalProps> = ({ open, onClose }) => {

    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [selectedContent, setSelectedContent] = useState<string | null>(null);
    const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
    const [notes, setNotes] = useState<string | null>(null);

    const handleItemClick = (item: HelpItem) => {
        if (item.content) {
            setSelectedContent(item.content);
            setSelectedTitle(item.title);
            setNotes(item.notes ? item.notes : null);
        }
        if (!!item.children) {
            setExpandedItems((prev) =>
                prev.includes(item.title) ? prev.filter((x) => x !== item.title) : [...prev, item.title]
            );
        }
    };

    const renderHelpTree = (items: HelpItem[], level = 0) => {
        return (
            <List disablePadding>
                {items.map((item) => (
                    <React.Fragment key={item.title}>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => handleItemClick(item)}
                                sx={{ pl: level * 2 }} // Indentation
                            >
                                <ListItemIcon>
                                    {item.children ? (
                                        expandedItems.includes(item.title) ? (
                                            <KeyboardArrowDownIcon />
                                        ) : (
                                            <KeyboardArrowRightIcon />
                                        )
                                    ) : (
                                        <HelpOutlineIcon />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                        {item.children && expandedItems.includes(item.title) && renderHelpTree(item.children, level + 1)}

                        <Divider />

                    </React.Fragment>
                ))}
            </List>
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ width: {xs: '100%', md: '30%'}, borderRight: {md: '1px solid #ccc'}, pr: {md: 2} }}>
                    <Typography variant="h6" className="text-heading" gutterBottom>Help Topics</Typography>
                    {renderHelpTree(helpData)}
                </Box>
                <Box sx={{ width: {xs: '100%', md: '70%'}, pl: {md: 2}, pt: {xs: 2, md: 0}, overflowY: 'auto'}}>
                    {selectedContent && (
                        <>
                            <Typography variant="h6">
                                {selectedTitle}
                            </Typography>
                            { notes &&
                            <Alert severity="info" sx={{marginTop: "0.5rem", marginBottom: "1rem"}}>
                                {notes}
                            </Alert>
                            }
                            <Typography variant="body1" component="div">
                                {selectedContent}
                            </Typography>
                        </>
                    )}
                    {!selectedContent && <Typography variant="body1" className="text-main">Select a help topic to view content.</Typography>}
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
        </Modal>
    );
};

export default HelpModal;
