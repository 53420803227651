import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

const MobileMenu = ({scrollToContact}: {scrollToContact: () => void}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  
  useEffect(() => {
    const handleEscape = (e: any) => {
      if (e.key === 'Escape') {
        setMenuOpen(false);
      }
    };
    
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [menuOpen]);

  return (
    <div className="relative z-40 lg:hidden">
      {/* Overlay */}
      <div 
        className={`fixed inset-0 bg-black/50 transition-opacity duration-300 ease-in-out ${
          menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setMenuOpen(false)}
      />

      {/* Container for both button and menu */}
      <div 
        className={`fixed top-0 right-0 h-full transform transition-transform duration-300 ease-in-out ${
          menuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Menu button */}
        <div className="absolute -left-16 top-4">
          <button 
            className="rounded-full text-center h-12 w-12 p-2 bg-gray-200 hover:bg-gray-300 transition-colors"
            onClick={() => setMenuOpen(!menuOpen)}
            aria-label={menuOpen ? 'Close menu' : 'Open menu'}
          >
            <div 
              className="w-fit justify-center m-auto">{menuOpen ? <X size={24} /> : <Menu size={24} />}
            </div>
          </button>
        </div>

        {/* Menu panel */}
        <div className="h-full w-64 bg-white shadow-lg">
          <div className="p-4">
            {/* Menu items */}
            <ul className="mt-12 space-y-4">
              <li>
                <button 
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={() => {
                        setMenuOpen(false);
                        window.location.href = "/login";
                    }}
                >
                  Log In
                </button>
              </li>
              <li>
                <button 
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={() => {
                        setMenuOpen(false);
                        scrollToContact();
                    }}
                >
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
