
import { useState, useContext } from "react";
import { AuthContext } from "../auth";

// Icon imports
import { AddLink, LinkOff, Logout } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpModal from "./help";
import { Alert, Backdrop, Button, CircularProgress } from "@mui/material";

export default function TopNav() {

	const authInfo = useContext(AuthContext);

	// Loading state
	const [loading, setLoading] = useState(false);
	const [fetchingAds, setFetchingAds] = useState(false);

	const [logoutWarning, setLogoutWarning] = useState("hidden");
    const [helpOpen, setHelpOpen] = useState(false);



    // Check for valid token on page load
	if(!authInfo) throw new Error("Invalid auth context.");

	const auth = authInfo.googleAuthed;

	async function handleLogout() {
		try {
			const res = await fetch('/logout');

			if(res.status === 200) {
				// Logout successful
                window.location.replace("/login");
			} else {
				// Display alert
				setLogoutWarning("block");
			}
		} catch {
			setLogoutWarning("block");
		}
	}


	// Driver function for Google authentication
	function authDriver() {
		if(authInfo) {
			setLoading(true);
			setFetchingAds(true);

			authInfo.authGoogle();
		}
	}

	function googleDeauthDriver() {
		if(authInfo) {
			authInfo.unauthGoogle();
		}
	}


    return (
		<div>
            <div className="bg-nav-bg absolute top-0 shadow h-16 w-screen grid grid-cols-2 items-center z-20">

                <Backdrop open={authInfo?.fetchingAuth || loading}>
					<div className="text-white">
    					<CircularProgress color="inherit"/>
					</div>
					<div className="pl-8 z-50">
						<h1 className="text-4xl font-bold text-white">{fetchingAds ? "Linking Google Account" : "Setting up your dashboard."}</h1>
						{
						fetchingAds ?
							<h1 className="text-3xl text-white">This may take up to 1 minute.</h1>
						:
							<></>
						}
					</div>
    			</Backdrop>

    			<div className={logoutWarning}>
    				<Alert severity="error" onClose={() => {setLogoutWarning('hidden');}}>Logout failed - try again later.</Alert>
    			</div>

                <HelpModal open={helpOpen} onClose={() => setHelpOpen(false)} />

                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-fit ml-20 md:ml-80 transition-all"
                    onClick={() => setHelpOpen(true)}
                >
                    <HelpOutlineIcon className="mr-2" /> Help
                </button>

                <div className="flex flex-row justify-end ml-80 lg:ml-0 absolute right-4">
                    <button
                        className="flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition-all mr-4"
                        onClick={() => { auth ? googleDeauthDriver() : authDriver() }}
                    >
                        {auth ? <LinkOff className="sm:mr-2" /> : <AddLink className="sm:mr-2" />}
                        <div className="hidden sm:block">{(auth ? 'Disconnect' : 'Connect') + ' Google Account'}</div>
                    </button>

                    <button onClick={() => {if(authInfo) handleLogout();}} className="lg:after:content-['Logout'] bg-white lg:bg-transparent rounded-md pl-4 pr-4 border-black border-2 lg:border-0 lg:hover:bg-[#FFFFFF44] transition-all"><Logout className="lg:mr-2"/></button>
                </div>
            </div>
		</div>
    );
}
