import { Alert, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../auth";
import { FullLogo, LogoNoText, SmallLogo } from '../ui/logo';

import { darkTheme } from "./signup";
import { ThemeProvider } from '@emotion/react';


export default function Login() {

    const authInfo = useContext(AuthContext);
    if (!authInfo) throw new Error("Invalid auth context.");

    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null); // State for error message
    const [width, setWidth] = useState(window.innerWidth);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      // Get token from server
      const response = await fetch('/login',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'email': email, 'password': password})
        }
      );

      const data = await response.json();

      // If a token was returned, log user in
      if (data.status === 'success' && authInfo) {
        console.log("Successfully logged in!");
        authInfo.login();
        authInfo.fetchUserData();
        navigate("/overview");

      } else if (data.status !== "success") {
        setError(data.status || "Login failed. Please check your credentials."); // Set error message
      }
    }

    // Redirect on page load if token is valid
    useEffect(() => {
      fetch('/validate-token')
        .then(response => {
          if (response.ok && !response.url.includes("localhost")) {
            console.log(response);
            window.location.href = "/overview";
          } else {
            // handle error or redirect to login page if token is invalid
          }
        })
        .catch(error => console.error('Error validating token:', error));
    }, [authInfo.fetchingAuth]);


    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="w-screen h-screen bg-midnight">
            <ThemeProvider theme={darkTheme}>
                <form onSubmit={handleLogin} className="md:w-fit p-2 md:p-8 translate-y-[5%] md:translate-y-[20%] m-auto">

                    {/*<img src={logo} alt="Startbase Management Logo" className="w-full m-auto rounded-lg mb-6"/>*/}
                    <div className="mb-6"> 
                        { width > 768 ? <FullLogo className="m-auto"/> : <SmallLogo className="m-auto"/> };
                    </div>
                    <h1 
                        className="md:hidden text-6xl text-center font-semibold mb-2 text-white"
                    >
                        Startbase Management
                    </h1>


                    <h2 className='text-center text-white text-lg'>Elevate your business with streamlined automation.</h2>
                    <h1 className='text-center text-3xl font-bold text-white mb-8 mt-10'>Sign in</h1>

                    {error && (
                        <Alert severity="error" sx={{marginBottom: "2rem"}}>
                            {error}
                        </Alert>
                    )}

                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)} 
                        value={email}
                        name="email"
                        sx={{marginBottom: "1rem"}}
                    />

                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} 
                        name="email"
                        sx={{marginBottom: "2rem"}}
                    />

                    <div className="w-full flex justify-center">
                        <Button type='submit' variant='contained' sx={{margin: "auto"}}>
                                Sign In
                        </Button>
                    </div>

                    <Typography 
                        variant="body1" 
                        className="text-[#E2E8F0]"
                        sx={{textAlign: "center", marginTop: "1rem"}}
                    >
                        Don't have an account? <a href="/subscribe?tier=1" className="text-primary">Sign up</a>
                    </Typography>

                </form>
            </ThemeProvider>
        </div>
    );
}
