import { useState, useContext, useEffect } from "react";
import { Alert, Button, Dialog, DialogContent, Divider, IconButton, Snackbar, Switch, TextField, Typography } from "@mui/material";
import Sidebar from "../ui/sidebar";
import TopNav from "../ui/top-nav";
import { AuthContext } from "../auth";
import { PhoneInput } from "react-international-phone";

import "../login/phone.css";
import { Close } from "@mui/icons-material";


type AlertSeverity = "error" | "success";

interface TextSettingProps {
    fieldName: string,
    label: string,
    defaultValue: string,
    endpoint: string,
    errorCallback: (severity: AlertSeverity, message: string) => void
}



function TextSetting({props}: {props: TextSettingProps}): JSX.Element {

    const [value, setValue] = useState<string>("");


    return (
        <div className="my-6 w-full md:w-2/3">
            <div className="flex justify-between items-center">
                <Typography
                    variant="body2"
                    className="text-main"
                >
                    {props.fieldName}
                </Typography>
                <Button
                    onClick={async () => {

                        let body: any = {};
                        body[props.label] = value;
                        
                        const res = await fetch(props.endpoint, {
                            method: "POST",
                            headers: {"Content-Type": "application/json"},
                            body: JSON.stringify(body)
                        });

                        const formattedFieldName = props.fieldName
                            .toLowerCase()
                            .replaceAll("google ads", "Google Ads");

                        if (!res.ok) {
                            // Alert user the field couldn't be updated
                            props.errorCallback("error", `We couldn't update your ${formattedFieldName}. Try again later.`);
                        } else {
                            props.errorCallback("success", `Successfully updated your ${formattedFieldName}!`);
                        }
                    }}
                >
                    Save
                </Button>
            </div>

            <TextField
                variant="standard" 
                placeholder={props.fieldName}
                defaultValue={props.defaultValue}
                onChange={(val) => setValue(val.target.value)}
                sx={{
                    width: "100%"
                }}
            />

        </div>
    );
}


export default function SettingsPage() {

    const authInfo = useContext(AuthContext);
    if (!authInfo) throw new Error("Invalid auth state.");
    const userData = authInfo.userData;
    
    useEffect(() => {
        if (!authInfo.authed && !authInfo.fetchingAuth) {
          window.location.replace("/login");
        }
    }, [authInfo.fetchingAuth]);

    console.log("--------USER DATA---------");
    console.log(userData);


    // Page layout state ------------------------------------------------------
    const [notice, setNotice] = useState<string>("");
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertSeverity>("success");
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);


    // Input value state ------------------------------------------------------
    const [phone, setPhone] = useState<string>(userData?.account_data.phone || "");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [pendingChecked, setPendingChecked] = useState<boolean>(userData?.account_data.booking_request_notify || false);
    
    
    const errorCallback = async (severity: AlertSeverity, message: string) => {
        setNotice(message);
        setSnackbarOpen(true);
        setSnackbarSeverity(severity);

        await authInfo.fetchUserData();
    };
    
    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />
            <TopNav />

            <main className="md:ml-64 p-8 pt-28 flex flex-col gap-8">
                <div>
                    <Typography variant="h4" component="h4" className="text-heading">
                        Settings
                    </Typography>
                    <Typography variant="body1" className="text-main">
                        Update your Startbase account settings.
                    </Typography>
                </div>
                <Divider />


                <div>
                    {/* Business Contact Settings */}
                    <Typography 
                        variant="h6" 
                        className="text-heading"
                    >
                        Contact Settings
                    </Typography>
                    
                    <TextSetting props={{
                        fieldName: "Contact Name",
                        defaultValue: userData?.account_data.contact_name || "",
                        label: "name",
                        endpoint: "/api/settings/update-name",
                        errorCallback: errorCallback
                    }}/>

                    <TextSetting props={{
                        fieldName: "Business Name",
                        defaultValue: userData?.account_data.name || "",
                        label: "name",
                        endpoint: "/api/settings/update-business-name",
                        errorCallback: errorCallback
                    }}/>

                    <TextSetting props={{
                        fieldName: "Email",
                        defaultValue: userData?.account_data.email || "",
                        label: "email",
                        endpoint: "/api/settings/update-account-email",
                        errorCallback: errorCallback
                    }}/>

                    <div className="my-6 w-full md:w-2/3">
                        <div className="flex justify-between items-center">
                            <Typography 
                                variant="body2" 
                                className="text-main"
                                sx={{marginBottom: "0.5rem"}}
                            >
                                Business Phone
                            </Typography>
                            <Button
                                onClick={async () => {
                                    const res = await fetch("/api/settings/update-phone", {
                                        method: "POST",
                                        headers: {"Content-Type": "application/json"},
                                        body: JSON.stringify({phone: phone})
                                    });

                                    if (!res.ok) {
                                        // Alert user the field couldn't be updated
                                        errorCallback("error", "We couldn't update your phone number. Try again later.");
                                    } else {
                                        errorCallback("success", "Successfully updated your phone number!");
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </div>
                        <PhoneInput
                            defaultCountry="au"
                            name="phone"
                            value={phone}
                            onChange={(val) => {setPhone(val)}} 
                            inputStyle={{
                                width: '100%', 
                                height: "3.5rem"
                            }}
                        />
                    </div>


                    {/* Business Account/Security Settings */}
                    <Typography 
                        variant="h6" 
                        className="text-heading"
                        sx={{marginTop: "3rem"}}
                    >
                        Account Settings
                    </Typography>
                    
                    {/*
                        TODO:
                        - Password
                    */}
                    <TextSetting props={{
                        fieldName: "Google Ads Customer ID",
                        defaultValue: userData?.account_data.customer_id || "",
                        label: "customer_id",
                        endpoint: "/api/settings/update-customer-id",
                        errorCallback: errorCallback
                    }}/>

                    <TextSetting props={{
                        fieldName: "Google Ads Manager ID",
                        defaultValue: userData?.account_data.manager_id || "",
                        label: "manager_id",
                        endpoint: "/api/settings/update-customer-id",
                        errorCallback: errorCallback
                    }}/>

                    <div className="w-full md:w-2/3">
                        <div className="flex justify-between">
                            <Typography 
                                variant="body2" 
                                className="text-main"
                                sx={{marginBottom: "0.5rem"}}
                            >
                                Password 
                            </Typography>

                            <Button onClick={() => {
                                // Verify confirmation
                                if (password !== confirmPassword) {
                                    setSnackbarSeverity("error");
                                    setNotice("Passwords must match.");
                                    setSnackbarOpen(true);
                                    return;
                                }

                                // Open current password verification dialog
                                setDialogOpen(true);
                            }}>
                                Save
                            </Button>
                        </div>

                        <div className="md:flex md:justify-between">
                            <TextField 
                                type="password"
                                variant="standard"
                                placeholder="New Password"
                                onChange={(val) => {setPassword(val.target.value)}}
                                sx={{
                                    width: {md: "48%", xs: "100%"}
                                }}
                            />
                            <TextField 
                                type="password"
                                variant="standard"
                                placeholder="Confirm Password"
                                onChange={(val) => {setConfirmPassword(val.target.value)}}
                                sx={{
                                    width: {md: "48%", xs: "100%"}
                                }}
                            />
                        </div>
                    </div>

                    {/* Notification Settings */}
                    <Typography 
                        variant="h6" 
                        className="text-heading"
                        sx={{marginTop: "3rem"}}
                    >
                        Notification Settings
                    </Typography>

                    <div className="flex items-center mt-6">
                        <Switch 
                            color="primary"
                            checked={pendingChecked}
                            onChange={async (x: React.ChangeEvent<HTMLInputElement>) => {
                                const checked = x.target.checked;

                                // Immediately display new value (bcus it looks nice :D)
                                setPendingChecked(checked);

                                const body = {
                                    notify: checked
                                };
                                const res = await fetch("/api/settings/set-request-notify", {
                                    method: "post",
                                    headers: {"Content-Type": "application/json"},
                                    body: JSON.stringify(body)
                                });
                                
                                if(res.ok) {
                                    setSnackbarSeverity("success");
                                    setNotice("Successfully updated your notification settings.");
                                } else {
                                    setSnackbarSeverity("error");
                                    setNotice("There was an error updating your pending booking notification settings.");
                                }
                                setSnackbarOpen(true);

                                await authInfo.fetchUserData();
                                
                                // Now set the switch to the *real* value (should be the same if update was successful)
                                setPendingChecked(userData?.account_data.booking_request_notify || checked);
                            }}
                        />
                        <Typography className="text-main">
                            Receive SMS notifications for new booking requests?
                        </Typography>
                    </div>
                    
                </div>


                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => {setSnackbarOpen(false)}} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {notice}
                    </Alert>
                </Snackbar>

                <Dialog 
                    open={dialogOpen} 
                    onClose={() => setDialogOpen(false)}
                >
                    <DialogContent>

                        <div className="w-full flex justify-between items-center">
                            <Typography 
                                variant="h6" 
                                className="text-heading"
                            >
                                Verify your current password.
                            </Typography>

                            <IconButton onClick={() => {setDialogOpen(false)}}>
                                <Close />
                            </IconButton>
                        </div> 

                        <div className="w-full mt-4 mb-6">
                            <TextField 
                                variant="filled"
                                type="password"
                                placeholder="Current password"
                                fullWidth
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </div>
                        <div className="w-fit">
                            <Button 
                                onClick={async () => {
                                    // We know the password and confirmation match, now send the new and 
                                    // old password to the server for verification and updating

                                    const body = {
                                        current_password: currentPassword,
                                        new_password: password
                                    };

                                    const res = await fetch("/api/settings/change-password", {
                                        method: "POST",
                                        headers: {"Content-Type": "application/json"},
                                        body: JSON.stringify(body)
                                    });

                                    let text = await res.text();
                                    if (text.includes("DOCTYPE html")) {
                                        text = "There was an error updating your password. Try again later";
                                    }
                                    
                                    if (!res.ok) {
                                        errorCallback("error", text);
                                    } else {
                                        errorCallback("success", "Successfully updated your password!");
                                    }
                                }}
                                variant="outlined"
                            >
                                Submit
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>

            </main>
        </div>
    );
}
