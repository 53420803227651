
import { 
  TextField, 
  Button, 
  Container, 
  Grid, 
  Typography, 
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  Backdrop,
  CircularProgress,
  Alert,
} from '@mui/material';

import { FullLogo } from "../ui/logo";
import PasswordStrengthIndicator from '../ui/password-strength';
import { PhoneInput } from 'react-international-phone';

import "./phone.css";
import { useEffect, useState } from 'react';



export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0F172A',
      paper: '#1E293B',
    },
    primary: {
      main: '#3B82F6',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#475569',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3B82F6',
          },
        },
      },
    },
  },
});


interface SignupFormData {
  email: string;
  phone_number: string;
  full_name: string;
  business_name: string;
  country: string;
  city: string;
  password: string;
  confirm_password: string;
  timezone: number;
}

//interface TierInformation {
//    name: string,
//    monthlyPrice: number,
//    features: Array<string>,  // Features it provides
//    missing: Array<string>,   // Features it doesn't provide
//    info: string
//}


// List of countries (expanded from previous version)
const COUNTRIES = [
  'Australia', 'New Zealand', 'United States', 'Canada', 'United Kingdom', 
  'Germany', 'France', 'Japan', 'Singapore', 'India'
  // Add more countries as needed
];

const EMAIL_ERROR = "We weren't able to email you the link to this page, so save it if you want to continue account creation later.";

//const TIER_INFORMATION: Array<TierInformation> = [
//    {
//        name: "Basic", 
//        monthlyPrice: 230, 
//        features: BASIC_FEATURES, 
//        missing: PRO_FEATURES, 
//        info: ""
//    },
//    {
//        name: "Pro", 
//        monthlyPrice: 490, 
//        features: BASIC_FEATURES.concat(PRO_FEATURES), 
//        missing: [], 
//        info: ""
//    },
//];
//
//function formatFeatures(tier: TierInformation): React.ReactElement {
//    return (
//        <div className="ml-4 text-[#E2E8F0]">
//            <ul className="list-['✓'] mt-4 text-lg">
//                {tier.features.map(
//                    (f: string, i: number) => <li key={i} className="pl-4">{f}</li>
//                )}
//            </ul>
//
//            <ul className="list-['×'] text-lg text-gray-500">
//                {tier.missing.map(
//                    (m: string, i: number) => <li key={i} className="pl-4">{m}</li>
//                )}
//            </ul>
//        </div>
//    );
//}


function SignupForm() {



    const [passwordError, setPasswordError] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
    const [alertSeverity, setAlertSeverity] = useState<"error" | "info">("error");
    const [formData, setFormData] = useState<SignupFormData>({
      email: '',
      phone_number: '',
      full_name: '',
      business_name: '',
      country: '',
      city: '',
      password: '',
      confirm_password: '',
      timezone: new Date().getTimezoneOffset() 
    });


    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);

        const sessionId = urlParams.get("session_id");
        const tier = urlParams.get("tier");

        if(!sessionId) {
            // Inform user their URL is malformed 
            window.location.replace("/");
        }
        if(!tier) {
            window.location.replace("/");

        }

        let email = "";

        // Set state with returned checkout
        fetch(`/retrieve-checkout-session?session_id=${sessionId}`)
            .then((res) => {

                    if (!res.ok) {
                        window.location.replace("/");
                        return null;
                    }
                    return res.json()
                
                }).then((session) => {

                console.log("Received checkout session:");
                console.log(session);

                if (session.status === "open") {
                    //window.location.replace(`/subscribe?tier=${tier}`);
                } else if (session.status === "complete") {
                    // User has completed subscription, now permit account registration

                    email = session.customer_details.email;

                    // Set cookie with session ID
                    document.cookie = `checkout_session_id=${sessionId}`;
                    if (!email) {
                        setAlertSeverity("info");
                        setErrorMessage(EMAIL_ERROR);

                        return;
                    }

                    // Email user signup page link so they can return if needed
                    fetch("/api/send-checkout-link", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            url: window.location.toString(),
                            email: email
                        })
                    }).then((res) => {
                        if (!res.ok) {
                            // Notify user they couldn't be sent the account creation link
                            // (Tell them to manually save)
                            setAlertSeverity("info");
                            setErrorMessage(EMAIL_ERROR);
                        }

                        return res;
                    });
                }
            });
    }, []);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      
      // Special handling for password confirmation
      if (name === 'confirmPassword') {
        if (formData.password !== value) {
          setPasswordError('Passwords do not match');
        } else {
          setPasswordError('');
        }
      }

      setFormData((prev: any) => ({
        ...prev,
        [name]: value
      }));
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();


        if(passwordError.length > 0) {
            alert(passwordError);
            return;
        }

        setCreatingAccount(true);

        const response = await fetch("/create-user", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        });
        setCreatingAccount(false);

        if (!response.ok) {
            // Set alert that account creation failed
            if (response.status === 409) {
                // If CONFLICT status is returned, an account with that email exists
                setErrorMessage("An account with that email already exists.");
            } else if (response.status === 401) {
                setErrorMessage("You are unauthorized to create an account at this time.");
            } else if (response.status === 500) {
                setErrorMessage("An internal server error has occurred. Try again later.");
            } else if (response.status === 400) {
                setErrorMessage("You may have sent invalid data to Startbase, ensure you have the correct link and all information fields are correctly filled out.");
            } else {
                setErrorMessage("An unknown error has occurred. Try again later.");
            }

            return;
        }
        // If it was successful, redirect to the login page
        window.location.replace("/login");
    };

    return (
        <ThemeProvider theme={darkTheme}>
          <Container maxWidth={false} className="min-h-screen flex flex-col items-center justify-center p-4" style={{ backgroundColor: '#0F172A' }}>
            <Box className="w-full max-w-4xl mx-auto">
              <FullLogo className="mb-6 mx-auto" />

              <Backdrop open={creatingAccount}>
                  <div className="text-white">
                        <CircularProgress color="inherit"/>
                  </div>
                  <div className="text-white pl-8">
                      <Typography>Creating your Startbase account.</Typography>
                  </div>
              </Backdrop>

              { errorMessage &&
              <Alert severity={alertSeverity}>
                {errorMessage}
              </Alert>
              }

              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} sx={{
                    backgroundColor: "background.paper",
                    borderRadius: "0.5rem",
                    marginTop: "2rem",
                    paddingTop: "1.5rem"
                }}>
                  <Typography variant="h4" sx={{textAlign: "center", color: "white", width: "100%"}}>
                    {new URLSearchParams(window.location.search).get("tier") === "1" ? "Pro" : "Basic"} Tier Account Creation
                  </Typography>
                  <Grid item xs={12} sx={{paddingRight: "1.5rem"}}>
                    {/*
                    <Typography variant="h6" gutterBottom color="primary">
                      Account Information
                    </Typography>
                    */}
                    <Grid container spacing={2}>
                      {/* Account Information Fields */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Full Name"
                          name="full_name"
                          value={formData.full_name}
                          onChange={(x: any) => handleInputChange(x)}
                          margin="normal"
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Business Name"
                          name="business_name"
                          value={formData.business_name}
                          onChange={(x: any) => handleInputChange(x)}
                          margin="normal"
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={(x: any) => handleInputChange(x)}
                          margin="normal"
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <PhoneInput
                              defaultCountry="au" // Set your default country
                              value={formData.phone_number}
                              onChange={(phone) => 
                                  handleInputChange(
                                      { target: { name: "phoneNumber", value: phone } } as React.ChangeEvent<HTMLInputElement>
                                  )
                              }
                              inputStyle={{
                                  width: '100%',
                                  padding: '8px 14px', // Adjust padding to match TextField
                                  border: '1px solid #e5e7eb40', // Match TextField border
                                  borderRadius: '4px', // Match TextField border radius
                                  boxSizing: 'border-box',
                                  height: '56px', //Match TextField height
                                  backgroundColor: "#1E293B",
                                  color: "white",
                              }}
                              className="mt-4"
                          />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <FormControl fullWidth margin="normal" required variant="outlined">
                          <InputLabel>Country</InputLabel>
                          <Select
                            name="country"
                            value={formData.country}
                            label="Country"
                            onChange={(x: any) => handleInputChange(x)}
                          >
                            {COUNTRIES.map((country) => (
                              <MenuItem key={country} value={country}>
                                {country}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          fullWidth
                          label="City"
                          name="city"
                          value={formData.city}
                          onChange={(x: any) => handleInputChange(x)}
                          margin="normal"
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Timezone"
                          name="timezone"
                          value={Intl.DateTimeFormat().resolvedOptions().timeZone}
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          margin="normal"
                          helperText="Automatically detected from your system"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PasswordStrengthIndicator password={formData.password} />
                        <TextField
                          fullWidth
                          label="Password"
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={(x: any) => handleInputChange(x)}
                          margin="normal"
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Confirm Password"
                          type="password"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={(x: any) => handleInputChange(x)}
                          margin="normal"
                          required
                          variant="outlined"
                          error={!!passwordError}
                          helperText={passwordError}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{paddingRight: "1.5rem", paddingBottom: "1.5rem"}}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      color="primary" 
                      fullWidth 
                      size="large"
                      className="mt-4"
                    >
                      Create Account
                    </Button>
                    <Typography 
                        variant="body1" 
                        color="textSecondary" 
                        sx={{textAlign: "center", marginTop: "0.5rem"}}
                    >
                        Already have an account? <a href="/login" className="text-primary">Log in</a>
                    </Typography>

                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </ThemeProvider>
    );
};

export default function Signup() {
  return (
    <SignupForm />
  );
}
