
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_test_51NPzOJIAZhuDJusNPapkJE8g3hpVK4Di0HlJsjRny069Cg4BGqGvBjQhuTHWxeylIfCgAYLFn1IZ71oZ1fFX1wAi003ZuoneZR", {});

export default function SubcriptionPage() {
    const options = {fetchClientSecret};

    return (
        <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
    )
}

// Retrieve checkout session client secret from Stripe API
async function fetchClientSecret(): Promise<string> {

    const params = new URLSearchParams(window.location.search);

    const tier = params.get("tier");
    if(!tier) {
        window.location.replace("/subscribe?tier=1");
    }
    
    return (
        await fetch(`/create-checkout-session?tier=${tier}`)
    ).text();
}
