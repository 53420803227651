import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../ui/sidebar";
import { Backdrop, Button, CircularProgress, Modal, TextField, Card, Snackbar, Alert } from "@mui/material";
import DayList from "./day-list";
import TopNav from "../ui/top-nav";
import { AuthContext } from "../auth";
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { parseMDate } from "../types";
import BlurOverlay from "../ui/paywall";

function previewMessage(input: string) {
	// Driver function for replaceTokens
	return replaceTokens(input, "-name-", "[Recipient's Name]");
}

function replaceTokens(input: string, token: string, replacement: string): string {
	return input.replaceAll(token, replacement);
}



export default function MarketingPortal() {

	const authInfo = useContext(AuthContext);
	if(!authInfo) throw new Error("Invalid auth context.");
	const userData = authInfo.userData;


    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
	const [creatingMsg, setCreatingMsg] = useState(false);
	const [repeating, setRepeating] = useState(false); // This lets the `DayList` communicate with the parent element, so we know if we want to repeat or not
	const [deleting, setDeleting] = useState(false); // Displays the loading animation when a marketing message is being deleted
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [scheduleData, setScheduleData] = useState({ // This is used to report the scheduling info back from DayList
        "datetime": new Date(), 
        "repeatDays": new Array<String>(), 
        "time": {} 
    }); 


	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.replace("/login");
		}
	}, [authInfo.fetchingAuth]);

    async function oneOffSchedule(datetime: Date, content: String) {
        // Format datetimes from input
        //const date = `${datetime.getFullYear()}-${datetime.getMonth()+1}-${datetime.getDate()}`; // %Y-%m-%d
        //const time = `${datetime.getHours()}:${datetime.getMinutes()}`; // %H:%M
        const response = await fetch('/schedule-message', {
            method: 'post',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({
                'scheduled_datetime': datetime,
                'content': content
            })
        });
        
        setLoading(false);
        if (!response.ok) {
            setSnackbarOpen(true);
            setErrorMessage("There was an error scheduling your one-off message.");
            return;
        }
        
        await authInfo?.fetchUserData();
        window.location.reload();
    }

    async function repeatingMessage(repeatDays: Array<String>, time: any, content: String) {
    
        const response = await fetch('/schedule-message', {
            method: 'post',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({
                'scheduled_datetime': time.$d,
                'content': content,
                'input_repeat_days': repeatDays
            })
        });

        setLoading(false);
        if (!response.ok) {
            setSnackbarOpen(true);
            setErrorMessage("There was an error scheduling your repeating message.");
            return;
        }
    
        await authInfo?.fetchUserData();
        window.location.reload();
    }


	const columns: GridColDef[] = [
		{ field: "message", headerName: "Message Content", flex: 1 },
		{ field: "repeats", headerName: "Repeat Days", flex: 1, valueGetter: (params: GridValueGetterParams) => {


            // Parse the datetime into a local Date object
            const message_date = parseMDate(params.row.datetime);

			const days = [' Mon', ' Tue', ' Wed', ' Thu', ' Fri', ' Sat', ' Sun'];

			if(params.row.repeat) {
				// Get all day names in the repeat list
				const dayNames = days.filter((day: string, i: number) => params.row.repeat.hasOwnProperty(i+1));
				return `${message_date.toTimeString().split("GMT")[0]} on ${dayNames}`;
			}

			return `One-off message on ${message_date.toLocaleString()}`
		}},
		{ field: "deleteButton", headerName: "Delete Message", flex: 1, renderCell: (params: any) => {
			return (
				<Button onClick={async () => {
					// Display loading animation
					setDeleting(true);

					const reminderID : string = params.row._id.$oid;
					const res = await fetch(`/delete-scheduled-message?id=${reminderID}`, {
						method: "DELETE"
					});

					// Now fetch new data (after deletion)
                    setDeleting(false);
                    if (!res.ok) {
                        setSnackbarOpen(true);
                        setErrorMessage("There was an error deleting your scheduled message.");
                        return;
                    }

					await authInfo.fetchUserData();

                    // Trigger re-render 
                    window.location.reload();
				}}>
                    x
                </Button> as React.ReactNode
			);
		}}
	];

	function getRowId(row: any) {
		return row._id.$oid;
	}

    return (
        <div className="bg-slate-50 h-screen">

			<Backdrop open={deleting} className="text-center z-50">
				<CircularProgress />
			</Backdrop>

            <Backdrop open={loading} className="text-center z-50 text-white">
                <CircularProgress sx={{color: "white"}}/>
                <h1 className="text-center text-white text-4xl pl-4">Scheduling ...</h1>
            </Backdrop>

            <Sidebar />
			{/*
            <Modal open={scheduling} onClose={() => setScheduling(false)}>
                <DayList messageContent={message}/>
            </Modal>
			*/}

            <TopNav />
            <BlurOverlay accountData={userData?.account_data || null} requiredTier={1}/>

            <main className='md:ml-64 p-8 flex flex-col pt-28 justify-center'>

                <h1 className="text-2xl text-heading mb-8">Marketing Message Portal</h1>

                <div className="bg-white rounded-md p-8">

                    {/*<h1 className="text-lg text-primary mb-8">Marketing Message Portal Overview</h1>*/}
					
					<div className="flex flex-col-reverse md:flex-row justify-between pb-8">
						<TextField label="Search"/>

                        <div className="w-4 h-4"/>

						<Button variant="contained" onClick={() => setCreatingMsg(true)}>
                            <RateReviewOutlinedIcon className="mr-2"/> 
                            <span className="mt-2 md:mt-0 mb-2 md:mb-0">Create Marketing Message</span>
                        </Button>

					</div>

					{/* Display list of messages to be sent */}
					<h1 className="pb-2 text-heading font-semibold">Currently Scheduled Messages</h1>
					<DataGrid 
						getRowId={getRowId}
						rows={ userData?.scheduled_messages || [] }
						columns={columns}
					/>

					<Modal open={creatingMsg} onClose={() => setCreatingMsg(false)}>
						<Card className="p-4 md:p-10 pb-6 rounded-md bg-white w-screen md:w-2/3 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 max-h-screen overflow-scroll">
							
							<div className="flex flex-row justify-between">
								<h1 className="text-2xl text-heading font-bold">Create Marketing Message</h1>
								<CloseIcon onClick={() => setCreatingMsg(false)} className="cursor-pointer transition-all hover:bg-[#0000000A] m-2 rounded-md"/>
							</div>

							<div className="lg:flex lg:flex-row justify-evenly">

								{/* Message Editor */}
								<div className="lg:w-1/2 self-center lg:justify-self-center p-4 lg:p-8">
									<div>
										<h2 className="text-left text-md text-main mb-2 font-semibold">Choose a Scheduling Routine</h2>
									</div>

									{/* Time/date selector for marketing message */}
                					<DayList messageContent={message} setRepeating={setRepeating} reportData={setScheduleData}/>

									<div>
										<div className="flex flex-row justify-between pb-2">
											<h1 className="text-md text-main text-left font-semibold self-center">Edit Message Below</h1>
											<div className="text-center">
												<Button onClick={() => setMessage(message + " -name- ")}><BadgeOutlinedIcon className="mr-1"/> Add Name</Button>
											</div>
										</div>
										<TextField multiline minRows={4} fullWidth onChange={(event) => setMessage(event.target.value)} value={message} ></TextField>
									</div>


								</div>


								{/* Message Preview */}
								<div className="lg:w-1/2 bg-[#F4F9FF] rounded-lg p-4 lg:p-8">
									<h1 className="text-md text-main pb-4 font-semibold">Message Preview</h1>
									<p className="bg-white rounded-lg border-2 h-3/4 p-2">{previewMessage(message)}</p>
								</div>

							</div>


							{/* Send button panel */}
							<div className="flex flex-row justify-center pt-4">

								{/*<Button variant="contained" onClick={sendMessage}>Send</Button>*/}
								<div className="w-2"></div>
								<Button variant="contained" onClick={() => {
									// Now we can schedule the message
                                    
                                    setLoading(true);

									if(repeating) {
										// Set repeating schedule
										console.log(`Scheduling a repeating message on: ${scheduleData['repeatDays']} at ${scheduleData['time']}`);
										repeatingMessage(scheduleData['repeatDays'], scheduleData['time'], message);
									} else {
										// Schedule one-off message
										oneOffSchedule(scheduleData['datetime'], message);
										console.log(`Scheduling a one-off message on: ${scheduleData['datetime']}`);
									}	
									
                                    // Close modal
                                    setCreatingMsg(false);

								}}>Schedule</Button>
							</div>
						</Card>
					</Modal>

                </div>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => {setSnackbarOpen(false)}} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

            </main>
        </div>
    );

}
