import { BarChart } from '@mui/x-charts/BarChart';

import { parseMDate } from "../types";

export default function BookingStats({ bookingList } : { bookingList: Array<Object> }) {

    const DAYS_TO_MILLIS = 24 * 60 * 60 * 1000;


    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let days: Array<string> = [];
    for(let i=0; i<7; i++) {
        // Get list of day names from today onwards
        const currDay = new Date().getDay();
        days.push(dayNames[(currDay + i) % 7 ]);
    }

    function getNextWeekBookingCounts(bookingList : Array<any>): [Array<any>, number] {

        let weeklyBookings : Array<number> = [0,0,0,0,0,0,0]; // Init empty weekly booking list
        const today = new Date();
        const weekFromNow = new Date(today.getTime() + 7*DAYS_TO_MILLIS); // Get datetime in 1 week
        
        let weeklyCount = 0;

        // Iterate through each booking to check if it's within the next week
        bookingList.forEach((booking) => { 
            try {
                // If the date is within the next week 
                const bookingDate = parseMDate(booking.appt_date)

                if(bookingDate < weekFromNow && bookingDate > today) {
                    // Add it to the correct x-axis band

                    let dateIndex: number;
                    if (bookingDate.getDay() > today.getDay()) {
                        dateIndex = bookingDate.getDay() - today.getDay() - 1;
                    } else {
                        // Handle case where the booking is at an earlier day next week
                        // e.g. today is Wed, booking is on Mon next week
                        dateIndex = today.getDay() - bookingDate.getDay() - 1;

                        if(bookingDate.getDay() == 0) {
                            dateIndex = bookingDate.getDay() - today.getDay() + 6; // Sunday is a special case
                        }
                    }



                    weeklyBookings[dateIndex] += 1;

                    // Add to overall count
                    weeklyCount += 1;
                }
            } catch {}
        });
        // Return list of bookings 
        return [weeklyBookings, weeklyCount];
    }


    const [weeklyBookings, numWeeklyBookings] = getNextWeekBookingCounts(bookingList);
    return (
        <div className=''>

            <h1 className='text-center text-main'><strong>{numWeeklyBookings}</strong> Bookings over the next <strong>7</strong> days.</h1>
            <BarChart 
                xAxis={[{ scaleType: 'band', data: days }]}
                series={[{ data: weeklyBookings }]}
                height={200}
            />
        </div>
    );
}
