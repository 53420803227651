import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Backdrop, Fab, Typography } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder'
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MenuIcon from '@mui/icons-material/Menu';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InboxIcon from '@mui/icons-material/Inbox';
import { AuthContext } from "../auth";


const logo = require('../images/white-logo.png');


export default function Sidebar() {

    const navigate = useNavigate();
	const authInfo = useContext(AuthContext);

	const initMenuButtonState = window.innerWidth < 768 ? "block" : "hidden";

	const [menuButton, setMenuButton] = useState(initMenuButtonState);
	const [menuState, setMenuState] = useState(initMenuButtonState === 'block' ? 'hidden' : 'block');


	if(!authInfo) throw new Error("Invalid auth context.");

    const navItems = [
        {'name': 'Overview', 'icon': <StarBorderIcon />, 'link': '/overview',  'id': 0},
        //{'name': 'Generate Ads', 'icon': <MonitorOutlinedIcon />, 'link': '/new-ad',  'id': 1},
        {'name': 'AI Analysis', 'icon': <DeveloperBoardOutlinedIcon />, 'link': '/ai-insights',  'id': 2},
        {'name': 'Record New Booking', 'icon': <PostAddIcon />, 'link': '/bookings',  'id': 3},
        {'name': 'Booking Calendar', 'icon': <CalendarMonthOutlinedIcon />, 'link': '/calendar',  'id': 4},
        {'name': 'Reminders', 'icon': <MarkChatUnreadOutlinedIcon />, 'link': '/reminders',  'id': 5},
        {'name': 'Marketing Portal', 'icon': <RateReviewOutlinedIcon />, 'link': '/marketing',  'id': 6},
        {'name': 'Inbox', 'icon': <InboxIcon />, 'link': '/inbox', 'id': 7},
		{'name': 'Pending Bookings', 'icon': <PendingActionsIcon />, 'link': '/pending', 'id': 8},
        {'name': 'Settings', 'icon': <SettingsOutlinedIcon />, 'link': '/settings',  'id': 9},
    ];



    function ListItem(icon: JSX.Element, name: string, link: string, index: number) {

        let classes = "rounded-r-lg pt-2 pb-2 pl-6 mt-1 mb-1 cursor-pointer transition-all ";

		// Check if selected
        if(window.location.pathname === link) { classes += 'text-white '; }
		else {classes += 'text-slate-400 ';}

        return (
            <li className={classes} key={index} onClick={() => {
                    // Set correct nav item to selected
                    navigate(link);
					return;
                }}>

                {icon}<span className="ml-2">{name}</span>
            </li>
        );
    }

    // AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA CHANGE THIS
	window.addEventListener('resize', () => {
		if(window.innerWidth < 768) {
			setMenuButton("block");
			setMenuState("hidden");
		} else {
			setMenuButton("hidden");
			setMenuState("block");
		}
	});

    

	function toggleMenu(_menuBtnContainer: EventTarget) {
		if(menuState === 'hidden') {
			setMenuButton("block transform translate-x-64")
			setMenuState('block');
		} else {
			setMenuButton('block');
			setMenuState('hidden');
		}
	}


    return (
        <>
            <aside className="fixed left-0 w-64 z-40">

		    	<div onClick={(e) => {toggleMenu(e.target)}} className={menuButton + " absolute top-2 left-2 transition-all"}>
		    		<Fab>
		    			<MenuIcon />
		    		</Fab>
		    	</div>

		    	<div className={"w-full bg-midnight h-screen pt-6 " + menuState}>
		    		<ul className="flex flex-col ">
.
		    			<img src={logo} className="w-3/4 mb-8 ml-6" alt="The Startbase logo, accompanied with text 'Startbase Management'."/>

                        <Typography 
                            variant="body1" 
                            className="text-slate-200 text-center"
                            sx={{marginBottom: "1.5rem"}}
                        >
                            {authInfo.userData?.account_data.name || ""}
                        </Typography>
		    			
		    			<h3 className="text-slate-400 text-xs pl-6 mb-2">MENU</h3>

		    			{navItems.map((item, index) => {
		    				return (
		    					ListItem(item.icon, item.name, item.link, index)
		    				)
		    			})}
		    		</ul>
		    	</div>
            </aside>
            <Backdrop 
                open={menuState === "block" && menuButton.startsWith("block")} // This check ensures we only display the backdrop on mobile devices
                onClick={(e) => toggleMenu(e.target)}
                className="z-30"
            />
        </>
    );
}
